/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useReducer, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { getGeneralError } from "../../../util/helperFunctions";
import { PageProps } from "../../../models/PageProps";
import { strings } from "../../../common/Strings/Strings";
import { CustomerForm } from "../../Accounting/CreateInvoice/PaymentAndCustomerInfo";
import CostCalculator from "../../../util/CostCalculator";
import { generateUUId } from "../../ePrescription/sections/finish/sendingRequestProcesser";
import { Loader } from "../../../components/Loader";
import ErrorModal from "../../Accounting/CreateInvoice/ErrorModal";
import CountryApi from "../../../api/CountryApi";
import TestInvoiceApi from "../../../api/TestInvoiceApi";
import { TestInvoiceResponse } from "../../../models/invoice/TestInvoiceResponse";
import { TestInvoiceRequest } from "../../../models/invoice/TestInvoiceRequest";
import NewCustomCost from "../../Accounting/CreateInvoice/Products/NewCustomCost";
import TestInvoiceBillingForm from "./TestInvoiceBillingForm";
import AddedItems from "../../Accounting/CreateInvoice/AddedItems";
import TestInvoiceSuccessModal from "./TestInvoiceSuccessModal";
import InvoiceCartUtils, { CartItem } from "../../../util/InvoiceCartUtils";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import Button from "../../../components/Button";
import { ChevronRight } from "../../../common/Icons/ChevronRight";

const CreateTestInvoice = ({ setPageLoading }: PageProps) => {
  const { countryCode } = useParams<"countryCode">();

  const [invoiceResponse, setInvoiceResponse] = useState<TestInvoiceResponse>();

  const [error, setError] = useState<string | null>(null);
  const [sum, setSum] = useState<number>(0);
  const [cart, dispatch] = useReducer(InvoiceCartUtils.reducer, []);
  const [selectedCountry, setCountry] = useState<CountryDetailsResponse>();

  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);

  const methods = useForm<CustomerForm>({});

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    const loadCountry = async (code: string) => {
      try {
        const resp = await CountryApi.getCountryDetails(code);
        setCountry(resp.data);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setPageLoading(false);
      }
    };

    if (countryCode) {
      void loadCountry(countryCode);
    }
  }, [countryCode]);

  useEffect(() => {
    const costItems = cart.map((item) => ({
      price: item.price,
      quantity: item.quantity,
      vat: item.vat,
    }));

    setSum(
      CostCalculator.getTotalGrossPriceOfInvoiceItems(
        costItems,
        selectedCountry
      )
    );
  }, [cart, selectedCountry]);

  const addToCart = (item: CartItem) => {
    dispatch({
      type: "add",
      itemToAdd: { ...item, localId: item.localId || generateUUId() },
    });
  };

  const changeQuantity = (item: CartItem) => {
    dispatch({ type: "changeQuantity", itemToChange: item });
  };

  const changePrice = (item: CartItem) => {
    dispatch({ type: "changePrice", itemToChange: item });
  };

  const removeItem = (item: CartItem) => {
    dispatch({ type: "remove", itemToRemove: item });
  };

  const sendInvoice = async (request: TestInvoiceRequest) => {
    if (countryCode) {
      setPageLoading(true);
      try {
        const response = await TestInvoiceApi.createInvoice(
          countryCode,
          request
        );
        setInvoiceResponse(response.data);
        setSuccessModal(true);
      } catch (err) {
        setError(await getGeneralError(err));
        setErrorModal(true);
      } finally {
        setPageLoading(false);
      }
    }
  };

  const submit = ({
    country,
    houseDetails,
    name,
    email,
    city,
    street,
    taxNumber,
    zip,
  }: CustomerForm) => {
    const filteredCart = [...cart.filter((item: CartItem) => !item.disabled)];

    const request: TestInvoiceRequest = {
      name,
      taxNumber,
      email,
      street,
      houseDetails,
      zip,
      city,
      countryCode: country?.length === 1 ? country[0].countryCode : undefined,
      items: filteredCart,
    };

    void sendInvoice(request);
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6 space-y-6">
          <div className="flex items-center space-x-2">
            <div>
              <Link
                to="/management/settings"
                className="lg:text-2xl"
              >
                {strings.settings}
              </Link>
            </div>
            <ChevronRight />
            <div>
              <Link
                to={`/management/countries/${countryCode}/payment-and-billing`}
                className="lg:text-2xl"
              >
                {selectedCountry?.countryName}
              </Link>
            </div>
            <ChevronRight />
            <span className="lg:text-2xl">{strings.createTestInvoice}</span>
          </div>
          <div className="lg:grid lg:grid-cols-3 lg:gap-6 space-y-6 lg:space-y-0">
            <div className="col-span-2 space-y-6">
              <div>
                <FormProvider {...methods}>
                  <form>
                    <TestInvoiceBillingForm />
                  </form>
                </FormProvider>
              </div>
              <div className="tw-card">
                <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex p-4">
                  {strings.addProducts}
                </h2>
                <div className="p-4">
                  <NewCustomCost
                    submit={addToCart}
                    countryDetails={selectedCountry}
                  />
                </div>
              </div>
            </div>
            <div className="space-y-6">
              <AddedItems
                removeItem={removeItem}
                changeQuantity={changeQuantity}
                sum={sum}
                cart={cart}
                changePrice={changePrice}
                currency={selectedCountry?.currency}
                country={selectedCountry}
              />
              <AlertBox
                message={strings.testInvoiceWarning}
                type={AlertType.WARNING}
                closeAble={false}
              />
              <div>
                <Button
                  onClick={handleSubmit(submit)}
                  disabled={!isDirty || cart.length === 0}
                >
                  {strings.finish}
                </Button>
              </div>
            </div>
          </div>
        </div>
        {countryCode && (
        <TestInvoiceSuccessModal
          close={() => {
            setSuccessModal(false);
          }}
          isOpen={successModal}
          countryCode={countryCode}
          invoice={invoiceResponse}
        />
      )}
        <ErrorModal
          isOpen={errorModal}
          close={() => {
            setErrorModal(false);
          }}
          error={error}
        />
      </section>
    </main>
  );
};

export default Loader(CreateTestInvoice);
