/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import { getGeneralError } from "../../../util/helperFunctions";
import { BillingSystem } from "../../../models/management/BillingSystem";
import { BillingSystemConfigResponse } from "../../../models/management/BillingSystemConfigResponse";
import BillingSystemConfigApi from "../../../api/BillingSystemConfigApi";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Button from "../../../components/Button";
import { Trash } from "../../../common/Icons/Trash";

interface Props {
  isActiveTab: boolean;
  countryCode: string;
  onLoadingChange: (isLoading: boolean) => void;
  onError: (error: string | null) => void;
  onConfigChange: (config?: BillingSystemConfigResponse) => void;
}

interface BillingConfigForm {
  system?: BillingSystem;
  clientId?: string;
  clientSecret?: string;
}

const BillingSystemConfig: React.FC<Props> = (props: Props) => {
  const { isActiveTab, countryCode, onLoadingChange, onError, onConfigChange } =
    props;

  const [selectedBillingSystem, setSelectedBillingSystem] =
    useState<BillingSystem>();

  const billingConfigForm = useForm<BillingConfigForm>({
    mode: "onChange",
  });

  const initBillingConfigForm = (
    config: BillingSystemConfigResponse | undefined
  ) => {
    billingConfigForm.reset();
    billingConfigForm.setValue("system", config?.system);
    billingConfigForm.setValue("clientId", config?.clientId);
    billingConfigForm.setValue("clientSecret", config?.clientSecret);
    setSelectedBillingSystem(config?.system);
    onConfigChange(config);
  };

  const getBillingSystemConfig = async () => {
    onLoadingChange(true);
    try {
      const resp = await BillingSystemConfigApi.getConfig(countryCode);
      initBillingConfigForm(resp.data);
    } catch (err) {
      onError(await getGeneralError(err));
    } finally {
      onLoadingChange(false);
    }
  };

  const deleteBillingSystemConfig = async () => {
    onLoadingChange(true);
    try {
      await BillingSystemConfigApi.deleteConfig(countryCode);
      initBillingConfigForm(undefined);
    } catch (err) {
      onError(await getGeneralError(err));
    } finally {
      onLoadingChange(false);
    }
  };

  useEffect(() => {
    if (!isActiveTab) return;
    void getBillingSystemConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveTab, countryCode]);

  const submitBillingConfig = async (f: BillingConfigForm) => {
    onLoadingChange(true);

    try {
      const resp = await BillingSystemConfigApi.updateConfig(countryCode, f);
      initBillingConfigForm(resp.data);
    } catch (err) {
      onError(await getGeneralError(err));
    } finally {
      onLoadingChange(false);
    }
  };

  return (
    <div className="tw-card">
      <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex p-4">
        {strings.billingSystem}
      </h2>
      <FormProvider {...billingConfigForm}>
        <form
          className="p-4 space-y-4"
          onSubmit={billingConfigForm.handleSubmit(submitBillingConfig)}
        >
          <Select
            name="system"
            label={strings.billingSystem}
            options={Object.keys(BillingSystem).map((i) => ({
              value: i,
              title: i,
            }))}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setSelectedBillingSystem(e.target.value as BillingSystem);
            }}
            register={billingConfigForm.register}
            required
          />

          {selectedBillingSystem === BillingSystem.FORTNOX && (
            <Field
              autoComplete={AutoCompleteOptions.off}
              label={strings.clientId}
              name="clientId"
              register={billingConfigForm.register}
              required
            />
          )}

          {selectedBillingSystem === BillingSystem.FORTNOX && (
            <Field
              autoComplete={AutoCompleteOptions.off}
              label={strings.clientSecret}
              name="clientSecret"
              register={billingConfigForm.register}
              required
            />
          )}
          <div className="pt-4 flex items-center">
            <div>
              <Button variant="icon" onClick={deleteBillingSystemConfig}>
                <Trash />
              </Button>
            </div>
            <div className="ml-auto">
              <Button
                type="submit"
                disabled={!billingConfigForm.formState.isValid}
                minW
              >
                {strings.save}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default BillingSystemConfig;
