/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const mainStringsHu = {
  noUnreadNotifications: "Nincs olvasatlan értesítés.",
  setupProfilePreferencesPhoneTitle: "Beállítások",
  setupProfilePhonePhoneTitle: "Telefon",
  setupProfileAddressPhoneTitle: "Lakcím",
  setupProfileRolePhoneTitle: "Felhasználói szerep",
  cookieConsentText:
    "Weboldalunkon „cookie”-kat (továbbiakban „süti”) alkalmazunk. Ezek olyan fájlok, melyek információt tárolnak webes böngészőjében. Ehhez az Ön hozzájárulása szükséges.",
  acceptCookie: "Sütik engedélyezése",
  declineCookie: "Elutasítás",
  pleaseSetupYourProfile: "Kérjük, egészítsd ki a regisztrációd.",
  wrongEmailOrPassword: "Helytelen email vagy jelszó!",
  title: "Cím",
  addNewExternalMedicalRecord: "Új jegyzet",
  medicalRecords: "Kórlapok",
  couldNotRetrieveMedicalRecords: "Kórlapok lekérése sikertelen",
  externalMedicalRecords: "Jegyzetek",
  externalMedicalRecord: "Jegyzet",
  couldNotRetrieveExternalMedicalRecords: "Jegyzetek lekérése sikertelen",
  maintenanceModeMessage:
    "Az oldal karbantartás alatt, Kérjük, látogasson vissza később!",
  maintenanceModeEnabled: "Karbantartás mód",
  maintenanceSettings: "Karbantartás",
  vetDocumentsWereRejected: "A documentumokat elutasították.",
  vetDocumentSubmitted: "A dokumentum feltöltődött és elfogadásra vár.",
  ePrescriptions: "eReceptek",
  couldNotRetrievePrescriptions: "Receptek lekérése sikertelen",
  submitDocument: "Dokumentum elküldése",
  addFile: "Fájl hozzáadása",
  farmAnimals: "Haszonállatok",
  createEmergencyAppointment: "Sürgősségi foglalás létrehozása",
  emergencyAppointment: "Sürgősségi foglalás",
  serviceEnabledForCountry: "Szolgáltatás elérhető",
  allowReservationFor: "Foglalható az alábbi szerepekhez",
  startOfAppointment: "Foglalás kezdete",
  endOfAppointment: "Foglalás vége",
  chooseAnEmploymentType: "Válasszon legalább egy szerepet.",
  employmentType: "Szerep",
  deceased: "Elhunyt",
  insuranceNumber: "Biztosítási szám",
  passportNumber: "Útlevélszám",
  alternativeMobilePhone: "Alternatív telefonszám",
  sendNewRegistrationConfirmationMail: "Új megerősítő email küldése",
  youCannotLoginBecauseEmailIsNotVerifiedYet:
    "Az Ön e-mail címe még nincs megerősítve.",
  fillPetDataBeforeMedicalRecordMsg:
    "A kórlap megnyitása előtt kérem töltse ki a szükséges adatokat.",
  proceedToMedicalRecord: "Tovább a kórlapra",
  emailNotConfirmed: "E-mail cím nincs megerősítve",
  emailConfirmationResendText: "E-mail cím megerősítésére kérhet új e-mailt.",
  resendConfirmationEmailBtnTxt: "E-mail újraküldése",

  phoneNotConfirmed: "Telefonszám nincs megerősítve",
  phoneConfirmationResendText: "Telefonszám megerősítésére kérhet új SMS-t.",
  resendConfirmationSmsBtnTxt: "SMS küldése",

  organizationNameIsIncorrect: "Helytelen szervezetnév.",
  organizationOwnersPassword: "Tulajdonos jelszava",
  organizationDeleteConfirmation:
    "Biztos ki szeretnéd törölni ezt a szervezetet?",
  organizationDeleted: "A szervezet sikeresen törölve lett.",
  deleteInProgress: "Törlés...",
  organizationDeletionConfirmationEmailSent:
    "Küldtünk egy emailt, melynek segítségével megerősítheti a szervezet törlését.",
  organizationName: "Vállalkozás neve",
  clinicDeletionWarning:
    "Figyelem! Minden klinikához kapcsolódó, eltárolt adat végleges törlésre kerül és a törlés nem vonható vissza.",
  organizationDeletionWarning:
    "Figyelem! Minden szervezethez kapcsolódó, eltárolt adat végleges törlésre kerül és a törlés nem vonható vissza.",
  clinicOwnersPassword: "Owner's password",
  clinicCreated: "A klinika létrehozása sikeres.",
  clinicDeleted: "A klinika sikeresen törölve lett.",
  clinicDeletionConfirmationEmailSent:
    "Küldtünk egy emailt, melynek segítségével megerősítheti a klinika törlését.",
  clinicName: "Klinika neve",
  other: "Egyéb",
  deleteClinic: "Klinika törlése",
  deleteOrganization: "Szervezet törlése",
  approveVets: "Orvosok megerősítése",
  userId: "Felhasználói azonosító",
  fileOrFiles: "Fájl(ok)",
  noFile: "Nincs fájl",
  viewFiles: "{0} fájl megtekintése",
  actions: "Műveletek",
  medicalCertificate: "Állatorvosi bizonyítvány",
  submitMedicinalCertificate: "Állatorvosi bizonyítvány feltöltése",
  vetProfessionNotValidated:
    "Receptek feltöltéséhez bizonyítania kell, hogy álllatorvosként dolgozik. Kérjük töltse fel az állatorvosi végzettségét igazoló dokumentumot.",
  vetProfessionDocumentsRejected:
    "A feltöltött dokumentumo(ka)t elutasították.",
  approve: "Elfogad",
  approveVetProfessionConfirmation:
    "Állatorvosi végzettség igazolásának megerősítése",
  approveVetProfessionConfirmationMessage:
    "Biztosan jóváhagyja az állatorvosi végzettséget igazoló dokumentumokat ehhez az állatorvoshoz?",
  documents: "Dokumentumok",
  document: "Dokumentum",
  rejectVetProfessionConfirmation:
    "Állatorvosi végzettség igazolásának elutasítása",
  rejectVetProfessionConfirmationMessage:
    "Biztosan elutasítja az állatorvosi végzettséget igazoló dokumentumokat ehhez az állatorvoshoz?",
  message: "Üzenet",
  rejectionMessagePlaceholder: "Írja le az elutasítás okait...",

  // emailTpyes
  PRIVATE: "Privát",
  PRIMARY: "Elsődleges",

  // Days
  Mon: "H",
  Tue: "K",
  Wed: "Sze",
  Thu: "Cs",
  Fri: "P",
  Sat: "Szo",
  Sun: "V",

  MONDAY: "Hétfő",
  TUESDAY: "Kedd",
  WEDNESDAY: "Szerda",
  THURSDAY: "Csütörtök",
  FRIDAY: "Péntek",
  SATURDAY: "Szombat",
  SUNDAY: "Vasárnap",
  Day: "Nap",
  Week: "Hét",
  Year: "Év",

  monday: "Hétfő",
  tuesday: "Kedd",
  wednesday: "Szerda",
  thursday: "Csütörtök",
  friday: "Péntek",
  saturday: "Szombat",
  sunday: "Vasárnap",
  day: "nap",
  week: "hét",
  today: "Ma",
  yesterday: "Tegnap",
  currentMonth: "Aktuális hónap",
  pastMonth: "Előző hónap",
  currentWeek: "Aktuális hét",
  pastWeek: "Előző hét",
  currentYear: "Aktuális év",
  pastYear: "Előző év",

  weekend: "Hétvégék",
  months: "Hónap",
  weeks: "Hetek",

  // Footer
  copyright: "Minden jog fenntartva ©",
  privacyStatement: "Adatkezelési beleegyezés",
  more: "Továbbiak",

  // Common
  serverNotResponding: "A szerver nem elérhető.",
  loading: "Betöltés...",
  pageNotFound: "Az oldal nem található.",
  incorrectFieldValue: "Néhány adat hibásan van kitöltve.",
  yes: "Igen",
  no: "Nem",
  OR: "VAGY",
  or: "vagy",
  selectAll: "Összes kijelölése",
  all: "Összes",
  optional: "opcionális",
  charactersLeft: "{0} karakter maradt",
  charactersAbove: "{0} karakterrel több",
  minimumCharacters: "A mezőnek legalább {0} karakter hosszúnak kell lennie.",
  maximumCharacters: "A mező legfeljebb {0} karakter hosszú lehet.",
  useCustomValue: "Saját érték használata:",
  showMore: "Mutass többet",
  showLess: "Mutass kevesebbet",
  systemId: "Rendszer azonosító",

  // Error messages
  notAllowedCharacter: "Nem megengedett karakter",
  notAllowedCharacterSpecific:
    "Nem megengedett karakter ({0}), ellenőrizze a Num lock kapcsolót.",
  pleaseFillRequiredFields: "Kérjük töltsd ki a hiányzó mezőket.",
  numberMustBePositive: "Pozitív szám legyen.",
  numberMustBeAnInteger: "Kerek szám legyen.",
  profitMarginMustBeLessThan100: "A haszonkulcs nem érheti el a 100%-ot.",

  // Nav
  myOrganizations: "Vállalkozásaim",
  createNew: "Új létrehozása",
  noActiveClinic: "Nincs aktív rendelő kiválasztva",
  pleaseChooseClinic:
    "Válasszon aktív rendelőt, vagy hozzon létre új vállalkozást, vagy új rendelőt meglévő vállalkozáshoz.",
  setActiveClinic: "Válasszon rendelőt...",
  search: "Keresés",
  searchPetOwner: "Állat vagy tulajdonos keresése",
  myPets: "Állataim",
  pets: "Házikedvencek",
  addPet: "Állat hozzáadása",
  findVet: "Rendelő keresése",
  settings: "Beállítások",
  noResult: "Nincs találat...",
  noResultTypeCustomValue: "Nincs találat, gépelj saját értéket",
  findClinic: "Rendelő keresése",
  findClinicByMap: "Rendelő keresése térképen",
  finish: "Lezárás",
  energySaveMode: "Energiatakarékos üzemmód",
  debugMode: "Debug mód",
  darkMode: "Sötét mód",
  contactSupport: "Ügyfélszolgálat",

  // login
  login: "Bejelentkezés",
  signIn: "Bejelentkezés",
  signInToYourAccount: "Jelentkezzen be a fiókjába",
  logout: "Kijelentkezés",
  signOut: "Kijelentkezés",
  switchAccount: "Fiókváltás",
  profile: "Profil",
  profileSettings: "Profilbeállítások",
  yourProfile: "Az ön profilja",
  setupYourProfileLater: "Profil beállítása később?",
  password: "Jelszó",
  petOrDoctor: "Ön állattulajdonos vagy orvos?",
  loginPetOwnerLabel: "Gazdi",
  loginVeterinaryWorkerLabel: "Állatorvosi dolgozó",
  registration: "Regisztráció",
  signUp: "Regisztráció",
  signUpHere: "Regisztráljon itt",
  loginWithFacebookText: "Folytatás a Facebookkal",
  loginWithGoogleText: "Folytatás a Google-lal",
  forgotPassword: "Elfelejtette a jelszavát?",
  newPassword: "Új jelszó",
  newPasswordAgain: "Új jelszó újra",
  savePassword: "Jelszó mentése",
  noAuthForLogin: "Nincs hozzáférése, hogy itt bejelentkezhessen.",
  workerLoginDisabled: "Nincs hozzáférése a dolgozói felülethez.",
  thirdPartyCookiesMustBeEnabled:
    "Engedélyeznie kell a harmadik féltől érkező sütiket a böngészőben.",
  petOwnerMode: "Gazda mód",
  employeeMode: "Klinika dolgozó mód",
  continueAsOwner: "Folytatás gazdaként",
  continueAsEmployee: "Folytatás alkalmazottként",
  ownerSideInfo: "Gazdaként itt tudja kedvenceit hozzáadni.",
  employeeSideInfo:
    "Klinika tulajdonosként vagy alkalmazottként itt tudja rendelőjét kezelni.",
  changeRoleInfo:
    "A profilmenüben bármikor válthat az orvos és a gazda mód között.",
  saveAndContinue: "Mentés és folytatás",
  alreadyHaveAccount: "Már van fiókja?",
  noAccountYet: "Nincs még fiókja?",
  loginHere: "Belépés itt",
  signInHere: "Bejelentkezés itt",
  backToLogin: "Vissza a bejelentkezéshez",

  // Multi-factor authentication
  mfaAuthentication: "Azonosítás",
  mfaDescriptionSms:
    "Kérjük, írja be az SMS-ben kapott kódot, hogy igazolja személyazonosságát.",
  mfaDescriptionEmail:
    "Kérjük, írja be az emailen kapott kódot, vagy kattintson az emailben levő gombra, hogy igazolja személyazonosságát.",
  mfaCancel: "Bezárás",
  mfaConfirm: "Megerősítés",
  mfaLoginFailed: "Sikertelen belépés.",
  mfaInvalidCodeFormat: "Érvénytelen formátum, 6 számjegynek kell lennie.",
  enterVerificationCode: "Ellenőrző kód beírása",

  // Globalvet settings
  afterAcceptContractText:
    "Gratulálunk, mostantól a teljes GlobalVet használható!",
  navigateToYourClinicPageLink: "Látogasson el a klinika oldalára",
  navigateToYourClinicPage:
    " és folytassa a felfedezést a GlobalVet rendszerében.",

  // ClinicInvoices
  from: "Ettől",
  to: "Eddig",
  invoiceState: "Számla állapota",
  invoiceCreationDate: "Számla kelt",
  pdfNotAvailable: "Sikertelen PDF letöltés",
  sendReminder: "Emlékeztető küldése",
  invoiceBillingInProgress: "Függőben lévő számlák",
  invoiceBilled: "Beküldött számlák",
  emailReminderSent: "Emlékeztető elküldve",
  withdrawingSuccessful: "A számla visszavonása sikeres volt",
  warningYouAreAboutToWithdrawAnInvoice: "Ön egy számla visszavonására készül.",
  addProducts: "Termékek hozzáadása",
  paymentAndCustomerInfo: "Fizetés és számlázási adatok",
  itemsAdded: "Hozzáadott tételek",
  proceedToInvoices: "Tovább a számlákhoz",
  tryLater: "Később próbálkozom",
  type: "Típus",
  searchToolTip:
    "Ügyfél név, személyi szám, telefonszám, e-mail cím, vagy állat neve",
  dateFilterToolTip:
    "Szűrés létrehozás dátumára. Szűrés részleges vagy teljes befizetés dátumára.",
  custom: "Saját",
  copyAsNew: "Másolás újként",
  addAllItemsToo: "Raktári tételek hozzáadása",
  addAllFeesToo: "Szolgáltatások hozzáadása",

  // Registration
  registerWithFacebookText: "Regisztráció Facebook fiókkal",
  registerWithGoogleText: "Regisztráció Google fiókkal",
  confirmEmailOk: "A megerősítő emailt elküldtük erre a címre: ",
  emailAlreadyUsed: "Ez az emailcím foglalt.",
  phoneAlreadyUsed: "Ez a telefonszám foglalt: ",
  emailAddress: "Email cím",
  enterYourEmail: "Adja meg az email címét",
  emailNotValid: "Érvénytelen email formátum.",
  verifyYourEmail: "Erősítse meg email címét",
  emailVerification: "Email cím megerősítés",
  emailVerificationLinkInvalid:
    "A megerősítő link érvénytelen, kérjük próbálja újra.",
  choosePassword: "Válasszon jelszót",
  passwordValid:
    "A jelszónak tartalmaznia kell legalább 1 kisbetűt, 1 nagybetűt, 1 számot, és legalább 8 karakteresnek kell lennie.",
  confirmPassword: "Jelszó megerősítése",
  confirmValid: "A két jelszónak meg kell egyeznie.",
  createAccount: "Fiók létrehozása",
  mobilePhone: "Mobiltelefon",
  mobilePhoneNumber: "Mobiltelefonszám",
  primaryPhoneNumberDetails:
    "Az elsődleges telefonszámodnak egy {0} kell lennie. Később hozzáadhatsz egy vezetékes telefonszámot is.",
  primaryPhoneNumberDetailsMobilePhone: "mobiltelefonszámnak",
  landlinePhone: "Vezetékes telefon",
  phoneNotValid: "Érvénytelen telefonszám",
  publicForVeterinaryWorkers: "Állatorvosi dolgozók számára nyilvános",
  prefix: "Titulus",
  prefixPlaceholder: "pl. Dr.",
  firstName: "Keresztnév",
  middleName: "Második keresztnév",
  lastName: "Vezetéknév",
  invalidPhoneNumber: "Érvénytelen telefonszám:",
  allowNotifications: "Engedélyezi az értesítéseket?",
  allowNotificationsButton: "Engedélyezés",
  notificationInfo:
    "Ha nem akar lemaradni a fontos eseményekről, engedélyezze az értesítéseket.",
  receiveSMSNotifications: "Értesítsen SMS-ben a fontos eseményekről",
  errorAccessingSocialData:
    "Hiba történt a szolgáltatótól való adatlekérdezés során.",
  accountAlreadyExists: "Ez a felhasználó már regisztrálva van.",
  successfulRegistration: "Sikeres regisztráció.",
  haveVetRegistrationNumber:
    "Állatorvosi dolgozó vagyok állami azonosító jellel.",
  registrationNumber: "Azonosító jel",
  addRegistrationNumber: "Azonosító hozzáadása",
  vetRegistrationNumbersTitle: "Állatorvosi azonosítók",
  registrationSuccessful: "Sikeres regisztráció",
  registrationSuccessfulMessage:
    "Gratulálunk! Sikeresen létrehozta fiókját. Üdvözöljük a GlobalVET-ben!",
  continueRegistrationMessage:
    "A regisztrációs folyamatot folytathatja a profilja beállításával a bejelentkezés után.",
  goToSignIn: "Tovább a bejelentkezéshez",
  pleaseCheckYourEmailInbox: "Kérjük, ellenőrizze az email fiókját!",
  sentYouEmailLink:
    "A megerősítő linket elküldtük a következő e.mail címre: {0}",
  pleaseCheckSpam: "Ellenőrizze a spam mappát is.",
  autoLoginChooseRole:
    "Kérjük válasszon, hogy állattulajdonosként vagy állatorvosi dolgozóként szeretne belépni.",
  autoLogin: "A bejelentkezés automatikusan megtörténik.",
  iHaveReadAndAcceptPolicy: "Elolvastam, és tudomásul vettem az ",
  iHaveInformedOwnerAboutPolicy:
    "Az állat tulajdonosát tájékoztattam a klinika adatakezelési irányelveiről és arról, hogy a GlobalVET AB a klinika nevében adatfeldolgozást végez.",
  pin: "PIN kód",
  setPin: "PIN kód beállítása",
  usePinInfoTitle: "Kényelmes és biztonságos használat",
  usePinInfo:
    "Az alkalmazás kényelmes és biztonságos használatához adjon meg egy PIN kódot.",
  pinUsage: "Gyors átjelentkezéshez és orvosi megerősítéshez.",
  pinFormat: "A PIN kód négy számjegyű kell legyen",
  savePin: "PIN kód mentése",
  pinSaved: "PIN kód sikeresen elmentve.",
  enterPin: "Írja be a PIN kódját.",
  vetNumber4digits: "Az azonosítónak 4 számjegyből kell állnia",
  countryFieldToolTip:
    "Az ország, melyben állatorvosi szolgáltatást nyúlt, vagy vesz igénybe.",
  countryOfResidence: "Tartózkodási ország",
  createPetOwner: "Új gazda létrehozása",
  gdprAgreement: "Egyetértek a GlovalVET {0}.",
  gdprAgreementGdpr: "GDPR Hozzájárulásával",
  clinicCreationAgreement: "Egyetértek a GlovalVET {0} és {1}.",
  clinicCreationAgreementGeneral: "Általános Szerződési Feltételeivel",
  clinicCreationAgreementDataProcessing: "Adatfeldolgozási Feltételeivel",
  fillMissingData: "Hiányzó adatok felvitele",

  // Password Recovery
  passwordRecovery: "Jelszó visszaállítása",
  recoveryEmailOk: "A visszaállító emailt elküldtük erre az email címre: ",
  recoverySmsOk: "A visszaállító SMS-t elküldtük erre a telefonszámra:  ",
  userNotFoundWithThisEmail: "Nem található felhasználó ezzel az email címmel.",
  userNotFoundWithThisPhone:
    "Nem található felhasználó ezzel a telefonszámmal.",
  simpleError: "Hiba történt.",
  sendRecoveryEmail: "Visszaállító email elküldése",
  sendRecoverySms: "SMS-kód küldése",
  recoverViaEmail: "Visszaállítás emailen keresztül",
  recoverViaMobile: "Visszaállítás mobiltelefonon keresztül",
  emailSentSuccessfully: "Sikeres e-mail küldés.",

  // AddNewPet
  newPet: "Új állat",
  addNewPet: "Új állat hozzáadása",
  newPetAndOwner: "Új állat és tulajdonos",
  newOwner: "Új tulajdonos",
  createNewOwner: "Új tulajdonos létrehozása",
  name: "Név",
  gender: "Nem",
  color: "Szín",
  species: "Faj",
  breed: "Fajta",
  dateOfBirth: "Születési dátum",
  invalidDate: "Hibás dátum.",
  years: "év",
  monthCnt: "hó",
  createProfileForPet: "Profil létrehozása",
  chipNumber: "Chipszám",
  tattooNumber: "Tetoválási szám",
  noPetYet: "Még nincs hozzáadva állat",
  addFirstPet: "Adja hozzá első állatát",

  // Edit Pet
  discardChanges: "Változtatások visszavonása",
  editPet: "Állat szerkesztése",
  seeAllPets: "Összes állat megtekintése",
  seeAllHistory: "Összes előzmény megtekintése",

  // Pet profile
  ownerPhone: "Gazda telefonszáma",
  applyFilters: "Szűrők alkalmazása",
  petProfile: "Állat adatlapja",
  editProfile: "{0} profiljának szerkesztése",
  ownerProfile: "Gazda adatlapja",
  searchBreed: "Fajta keresése...",
  uncompleted: "kitöltés alatt",
  mixed: "Keverék",
  specifyBreed: "Fajta megnevezése",

  // Pending pets
  pendingPets: "Függőben lévő állatok",
  seeAllPendingPets: "Összes függőben lévő állat megtekintése",
  acceptOwnership: "Legyen az én állatom",
  declineOwnership: "Elutasítás",
  changeOwner: "Gazda csere",
  currentPetOwner: "Aktuális gazda",
  addNote: "Üzenet hozzáadása",
  note: "Üzenet",
  clinicHasRegisteredYourPet: "{0} klinika regisztrálta az állatát.",
  createNewPet: "Új állat",
  updateExistingPet: "Meglévő állat kiegészítése",
  areYouSureToDecline: "Biztosan nem Ön az állat gazdája?",
  waitingForOldOwner:
    "Várakozás az előző gazdára, hogy elfogadja {0} gazda cseréjét.",
  waitingForNewOwner:
    "Várakozás az új gazdára, hogy elfogadja {0} gazda cseréjét.",
  acceptAsNewOwner: "{0} Önt jelölte meg {1} állata új gazdájaként",
  acceptAsOldOwner:
    "{0} elfogadta {1} gazda cseréjét. Ön is megerősíti a cserét?",
  newOwnerEmail: "Új gazda e-mail címe",

  // Email sending
  emailTo: "Címzett",
  emailSubject: "Tárgy",
  emailMessage: "Üzenet",
  attachToEmail: "Csatolás e-mailhez",
  emailSend: "Küldés",
  newEmail: "Új e-mail",
  defaultInsuranceEmailTitle: "Állatorvosi dokumentumok, {0} ({1})",
  petOwnerEmails: "petOwnerEmails",

  // MainOwner
  MAIN: "Kezdőlap",
  MY_PETS: "Állataim",
  APPOINTMENTS: "Foglalások",
  PRESCRIPTIONS: "Receptek",
  INVOICES: "Számláim",
  FIND_CLINIC: "Rendelő keresése",
  mainPage: "Kezdőlap",
  myBills: "Számláim",
  myAppointments: "Időpontjaim",
  addAppointment: "Új időpont",
  reserveButtonText: "Foglalás megerősítése",
  organization: "Szervezet",
  clinic: "Rendelő",
  date: "Dátum",
  reservationType: "Foglalás típusa",
  vet: "Állatorvos",
  pet: "Állat",

  // ProfilePet
  dontHaveAccessToPetProfile:
    "Nincs hozzáférésed ennek az állatnak a profiljához.",
  appointments: "Időpontok",
  pictureAlt: "Kép",
  warningChipNumberNotConfirmed: "A chipszám még nincs megerősítve.",

  // CalendarDayViewForm
  chooseCollab: "-- Válassz alkalmazottat --",
  searchEmployee: "Alkalmazott keresése...",
  assistant: "Asszisztens",
  authorizedAssistant: "Autorizált asszisztens",
  receptionist: "Recepciós",
  Vet: "Állatorvos",
  areYouSure: "Biztos benne?",
  deleteConfirmationTitle: "Törlés megerősítése",
  deleteConfirmation: "Biztosan ki szeretné törölni ezt a tételt?",
  backToCalendar: "Vissza a naptárra",
  editDailyForm: "Napi nézet szerkesztése",
  collaborators: "Alkalmazottak",
  id: "Azonosító",
  startTime: "Kezdés",
  endTime: "Befejezés",
  modify: "Módosít",
  addCollab: "Alkalmazott hozzáadása",
  deleteAll: "Összes törlése",
  addNewCollab: "Új alkalmazott hozzáadása",
  roleOfChosenCollab: "Választott alkalmazott szerepköre",
  repetition: "Ismétlődés",
  turnedOff: "Kikapcsolva",
  turnedOn: "Bekapcsolva",
  turnOffRepetition: "Ismétlődés ki- és bekapcsolása",
  chooseRepfordays: "Ezeken a napokon ismétlődjön:",
  setRepforWeeks: "Ennyi hét után",
  onThisDate: "Ekkor",
  end: "Vége",
  save: "Mentés",
  cancel: "Mégse",
  noShiftAtThisTime: "Ebben az időpontban nincs szabad munkatárs.",
  downloadMedicalRecordPDF: "Kórlap letöltése (PDF)",
  downloadInvoicePDF: "Számla letöltése (PDF)",
  downloadPDF: "PDF letöltése",
  openMedicalRecord: "Kórlap megnyitása",
  corrected: "Javítva",
  underCorrection: "Javítás alatt",

  // Substitution form
  substitution: "Helyettesítés",
  substitutionTitle: "Foglalások helyettesítése",
  absentEmployee: "Helyettesítendő alkalmazott",
  startDate: "Kezdő dátum",
  endDate: "Befejező dátum",
  startDateTime: "Kezdeti dátum és idő",
  endDateTime: "Vége dátum és idő",
  noReservationsSaveSubstitution:
    "{0} felhasználónak nincs időpontfoglalása {1} és {2} között. Törölheti a műszakjait.",
  cancelAndNotifyPetOwner: "Időpont lemondása és gazdi értesítése",
  chooseSubstitute: "-- Helyettes kiválasztása vagy időpont lemondása --",
  substitute: "Helyettesítés",
  employeeToDelete: "Helyettesítendő alkalmazott",
  saveAndDeleteEmployee: "Mentés és alkalmazott törlése",
  userHasReservationsBetween:
    "{0} felhasználónak {1} és {2} között foglalásai vannak. Állítson be helyetteseket, vagy mondja le a foglalásokat.",
  deleteShifts: "Műszakok törlése",
  deleteShift: "Műszak törlése",
  areYouSureToDeleteShift: "Biztosan törölni szeretné a műszakot?",
  deleteShiftsFor: "Műszakjainak törlése",
  selectShiftsFrom: "Műszakok keresése ettől",

  // OrganizationStatistics
  payments: "Befizetések",
  month: "Hónap",
  amount: "Összeg",
  status: "Állapot",
  pay: "Befizet",
  paySelected: "Kijelöltek befizetése",
  payWithRegisteredCard: "Fizetés regisztrált kártyával",
  payWithDifferentCard: "Fizetés más kártyával",
  paymentSuccessful: "Sikeres befizetés.",
  paymentUnSuccessful: "Sikertelen befizetés.",
  cause: "Indok",
  downloadInvoicePdf: "Számla letöltése",

  // Verification
  emailVerificationIsSuccessful: "Az email megerősítése sikeresen megtörtént.",
  emailVerificationIsSuccessfulInfo:
    "Most már bezárhatja ezt a fület vagy folytathatja a folyamatot a bejelenkezéssel.",
  errorDuringEmailVerification:
    "Probléma merült fel az email megerősítése során.",
  confirmEmail: "Megerősítő email",
  goToLogin: "Vissza a bejelentkezéshez",
  goToMainPage: "Vissza a főoldalra",
  redirectInSeconds: "Átirányítás {1} másodpercen belül...",

  // CreateClinic
  createClinic: "Rendelő létrehozása",
  bracnhName: "Rendelő neve",
  create: "Létrehozás",
  addBasicDetails: "Alapadatok megadása",
  addressSameAsOrgPrimaryAddress: "Megegyezik a vállalkozás elsődleges címével",
  orgHasNoPrimaryAddress: "A vállalkozásnak nincs elsődleges címe!",
  invalidAddress: "Helytelen cím",

  // CreateOrganization
  createOrganization: "Szervezet létrehozása",
  vatNotValid: "Az adószámnak legalább 5 számjegyűnek kell lennie.",
  chargeWarning:
    "A klinika havi költségei terhelve lesznek az adott kártyán a következő hónap első napján. Az összeg a használat alapján lesz megállapítva a szolgáltatási szerződésben meghatározott díjszabás alapján.",
  baseChargeWarning:
    "Ellenőrzés céljából egy kis összeggel megterhelhetjük a kártyáját, és ezt a regisztráció után visszatérítjük.",

  creditCardModification: "Bankkártya adatainak módosítása",
  giveNewCardData: "Kérem adja meg az új bankkártya adatait.",
  creditCardAuthFailed:
    "A bankkártyáját elutasítottuk, mivel a terhelés nem sikerült. Kérjük ellenőrizze a kártyáját.",
  creditCardDeclined: "A kártyáját elutasítottuk.",
  creditCardExpired: "A kártyája lejárt.",
  incorrectCVC: "A kártyájának a biztonsági kódja hibás.",
  incorrectZIP: "A kártyájának a ZIP kódja hibás.",
  incorrectNumber: "A kártyaszámát hibásan adta meg.",
  invalidCVC: "A kártyájának biztonsági kódja érvénytelen.",
  invalidNumber: "A kártyaszáma érvénytelen.",
  stripeHandleError:
    "A fizetésekhez kapcsolódó szolgáltatásaink jelenleg nem elérhetők. Kérjük próbálja később.",
  incorrectName: "A kártyán szereplő név hibás.",
  chargeFailed:
    "A befizetés sikertelen. Kérjük ellenőrizze, hogy a megadott kártya adatai helyesek-e.",
  chargeSucceeded: "A befizetés sikeresen megtörtént.",
  businessName: "Vállalkozás neve",
  businessRegistrationNumber: "Regisztrációs szám",
  invalidRegistrationNumber: "Érvénytelen regisztrációs szám",
  businessStructure: "Vállalkozási forma",

  // EditCategory
  cannotRetriveCatData: "Nem sikerült a kategória adatait betölteni.",
  deleteError: "Hiba történt a törléskor",
  editCategory: "Kategória szerkesztése",
  createCategory: "Kategória létrehozása",
  catNameWithDots: "Kategória neve...",
  delete: "Törlés",
  deletingCat: "Kategória törlése",
  areYouSureDeleteCat: "Biztos, hogy törölni akarja a(z) {0} nevű kategóriát?",
  hide: "Tételek elrejtése",
  see: "Tételek megjelenítése",
  newItem: "Új tétel",

  // EditItemModal
  cannotRetriveItemData: "Nem sikerült betölteni a tétel adatait.",
  editItem: "Tétel szerkesztése",
  createItem: "Tétel létrehozása",
  price: "Ár",
  itemPriceWithDots: "Tétel ára...",
  minimalLevel: "Alacsony feltöltöttség",
  stock: "Készlet",
  deleteItem: "Tétel törlése",
  areYouSureDeleteItem: "Biztos, hogy törölni akarja a(z) {0} nevű tételt?",
  chooseSpecies: "-- Válasszon fajt --",
  nameCannotBeEmpty: "A név kitöltése kötelező.",
  itemNameWithDots: "Tétel neve...",
  itemUnitWithDots: "Tétel mértékegysége...",
  deletingItem: "Tétel törlése",
  acquisitionPrice: "Beszerzési ár",
  acquisitionVat: "Beszerzési ÁFA-kulcs",
  salePrice: "Eladási ár",
  sellingVat: "Eladási ÁFA-kulcs",
  acquisitionUnitQuantity: "Beszerzési egységmennyiség",
  acquisitionUnit: "Mértékegység",
  sellingDivisor: "Eladási mennyiség osztója",
  currency: "Pénznem",
  applicableToSpecificSpecies: "Csak bizonyos fajnál alkalmazható",
  customPrice: "Egyedi ár",

  // FindClinic
  setAddress: "Kérjük, állítsa be a címét a profilján!",
  choooseThisClinic: "Rendelő kiválasztása",
  warningUserHasNoPrimaryAddress:
    "Először be kell állítani egy elsődleges címet, hogy időpontot tudjon foglalni.",
  warningUserHasNoPrimaryAddressLinkToProfile: "Profil beállítások módosítása.",

  // Inventory
  cannotRetriveClinicData: "Nem sikerült betölteni a rendelő adatait.",
  inventorySettings: "Raktárbeállítások",
  filter: "Szűrő",
  addItemFromVara: "VARA tétel hozzáadása",
  addItem: "Tétel hozzáadása",
  createNewInventoryItem: "Új raktári tétel létrehozása",
  noItemsAdded: "A listához még nem lett hozzáadva tétel.",
  areYouSureToDeleteCategory:
    "Biztosan törölni szeretné a raktár kategóriát? Az összes hozzá tartozó tétel is törlődni fog.",
  updateStock: "Készlet feltöltése",
  refillStock: "Bevételezés",
  refillStockInfo:
    "Megnöveli minden tétel készletét a 'Feltöltés' oszlop utolsó cellájában megadott mennyiségi értékekkel.",
  resetRefillValues: "Feltöltési értékek törlése",
  inventoryItemDetails: "Raktártétel tulajdonságai",
  orderHistory: "Bevételezési előzmények",
  orderHistoryUnit: "Bevételezés",
  usageHistory: "Kiadási előzmények",
  usageHistoryUnit: "Kiadás",
  countingHistory: "Leltározási előzmények",
  countingHistoryUnit: "Leltár",
  dateOfOrder: "Bevételezés dátuma",
  countedQuantity: "Leltározott mennyiség",
  countingDetails: "Leltározás részletei",
  totalQuantityInPackages: "Kiszerelés(ek)ben található mennyiség",
  unitPriceCalculator: "Egységár kalkulátor",
  totalPackagePrice: "Teljes kiszerelés ára",
  stockUsage: "Kiadás",
  editUsage: "Kiadás szerkesztése",
  editStock: "Bevételezés szerkesztése",
  deleteUsage: "Kiadás törlése",
  deleteStock: "Bevételezés törlése",
  deleteCounting: "Leltározás törlése",
  stockAddedSuccessfully: "Sikeres bevételezés.",
  stockUpdatedSuccessfully: "Bevételezés frissítve.",
  stockDeletedSuccessfully: "Bevételezés törölve.",
  areYouSureToDeleteItemStock: "Biztos, hogy törölni akarja a(z) {0} nevű tétel bevételezését?",
  areYouSureToDeleteItemUsage: "Biztos, hogy törölni akarja a(z) {0} nevű tétel kiadását?",
  areYouSureToDeleteItemCounting: "Biztos, hogy törölni akarja a(z) {0} nevű tétel leltározását?",
  countInventory: "Leltározás",
  stockOperations: "Készletkezelés",
  profit: "Nyereség",
  profitMargin: "Haszonkulcs",
  currentStock: "Bevételezett készlet",
  countedStock: "Leltározott készlet",
  countingDifference: "Eltérés",
  addMissingStock: "Hiányzó bevételezés felvétele",
  finishCounting: "Leltár véglegesítése",
  stockIsTheSame: "Készlet megegyezik",
  stockIsDifferent: "Készlet különbözik",
  salePriceOverTime: "Eladási ár ({0}) az elmúlt időben",
  purchasePriceOverTime: "Beszerzési ár ({0}) az elmúlt időben",
  purchaseVsSalePriceTitle: "Beszerzési ár és eladási ár alakulása",
  skip: "Kihagyás",
  firstStock: "Első bevételezés",
  lowStockToolTipInfo: "Ennél a mennyiségnél jelez a rendszer, ha fogyóban van a tétel.",
  firstStockSkipped: "Első bevételezés kihagyva.",
  itemCreatedSuccessfully: "A(z) {0} raktártétel sikeresen létrehozva.",
  itemUpdatedSuccessfully: "A(z) {0} raktártétel sikeresen frissítve.",
  inventoryManagementUpdatedInfo1: "Figyelem! Frissítettük a raktárkészlet-kezelés működését.",
  inventoryManagementUpdatedInfo2: "Újdonságok: bevételezés, leltározás, tétel szintű kimutatások és előzmények, haszonkulcs és egységár kalkulárotok.",

  // Analytics
  analytics: "Kimutatások",
  notEnoughData: "Nincs elég adat",

  // InventoryStatistics
  cannotRetriveInventoryData:
    "Nem sikerült a rendelő raktárának adatait betölteni.",
  itemName: "Tétel neve",
  showEverything: "Összes mutatása",
  showOnlyLow: "Alacsony mennyiségűek mutatása",
  showOnlyStock: "Készleten lévőek mutatása",
  category: "Kategória",
  warningAt: "Figyelmeztetés",
  lowLevel: "Alacsony mennyiség",

  // InventoryStatus
  refill: "Feltöltés",
  options: "Opciók",
  edit: "Szerkesztés",
  addToTreatment: "Kezeléshez adás",
  ok: "Rendben",
  operations: "Műveletek",
  saveChanges: "Változtatások mentése",

  // Invoice History
  costAccounting: "Költségelszámolás",
  treatmentData: "Kezelés adatai",
  invoiceDetails: "Számla adatai",
  code: "Kód",
  clinicIdentifier: "Rendelő azonosító",
  totalCost: "Összköltség",
  remainingCost: "Fennmaradó költség",
  sendReminderEmail: "Emlékeztető email küldése",
  amountToPay: "Fizetendő összeg",
  markAsPaid: "Fizetettnek jelölés",
  partialPayment: "Részleges fizetés",
  emailReminder: "Email emlékeztető",
  addCorrection: "Javítás hozzáadása",
  paidAmount: "Fizetett összeg",
  payerName: "Fizető neve",
  payer: "Fizető",
  payerType: "Fizető fél",
  PET_OWNER: "Állattulajdonos",
  INSURANCE_COMPANY: "Biztosítótársaság",
  paymentMethod: "Fizetési mód",
  paymentMethodShort: "Mód",
  newRemainingCost: "Fennmaradó összeg",
  customer: "Vevő",
  issuer: "Számla kibocsátója",
  paymentHistory: "Fizetési előzmények",
  modifierParty: "Módosító",
  paymentType: "Fizetés típusa",
  warningYouAreAboutToSendEmail: "Biztos benne, hogy elküldi az emailt?",
  emailReminders: "Elküldött emlékeztetők",
  onlinePayment: "Online fizetés",
  creditCardDetails: "Bankkártya adatai",
  giveYourCardData: "Kérem, adja meg a kártya adatait!",
  noData: "N/A",
  noInvoiceHistoryData: "Nincsenek fizetési előzmények.",
  notPaid: "Nem fizetett",
  filterByDateOfIssue: "Szűrés kiállítás dátuma szerint",
  filterByDateOfPayment: "Szűrés fizetés dátuma szerint",
  filterByName: "Szűrés név szerint",
  allowOnlinePaymentAndSendEmail:
    "Online fizetés engedélyezése és fizetési link küldése emailben",
  sentToFortnox: "Elküldve a Fortnox rendszernek",
  failedToSendToFortnox: "Nem sikerült elküldeni a Fortnox rendszernek",
  failedToConnectToFortnox: "Nem sikerült kapcsolódni a Fortnox rendszerhez",
  invoiceCreatedButFailedToSendToFortnox:
    "A számla létrehozása sikerült, de nem sikerült elküldeni a Fortnox rendszernek",
  sendToFortnox: "Beküldés a Fortnox rendszerébe",
  explanation: "Magyarázat",
  paymentDetails: "Fizetés részletei",
  paymentState: "Fizetési állapot",
  retry: "Újrapróbálkozás",
  noEmailReminderSent: "Nincs kiküldött emlékeztető email",
  download: "Letöltés",
  print: "Nyomtatás",
  printOrDownloadInvoice: "Szeretné letölteni vagy kinyomtatni a számlát?",
  noInvoicesToDisplay: "Nincs megjelenítendő számla",
  withdrawInvoice: "Számla visszavonása",
  estimatedCost: "Becsült költség",
  goToInvoices: "Számlák kezelése",
  editInvoice: "Számla szerkesztése",
  pendingInvoice: "Függőben",
  plusVat: "+ ÁFA",
  invalidAmount: "Érvénytelen összeg",
  gross: "bruttó",
  net: "nettó",
  grossPrice: "Bruttó ár",
  netPrice: "Nettó ár",
  cashIncome: "Készpénz bevétel",
  cardIncome: "Bankkártya bevétel",
  transferIncome: "Átutalás bevétel",
  directDebitIncome: "Beszedési megbízás bevétel",
  onlineIncome: "Online bevétel",
  insuranceIncome: "Biztosító által fedezett bevétel",
  totalIncome: "Teljes bevétel",
  paymentSummary: "Fizetési összesítő",
  invoicingMethod: "Kibocsátás módja",
  costSummary: "Költségösszegzés",
  incomeSummary: "Bevételek",
  sales: "Eladás",
  needInvoice: "Beküldés a Fortnoxba",
  fortnoxIsNotIntegrated: "A klinika nincs itegrálva a Fortnoxxal.",
  sameAsCustomerData: "Megegyezik a vevő adataival",
  billingInfo: "Számlainformáció",
  invoiceHasBeenSentInEmail:
    "A számla el lett küldve a következő e-mail címre:",
  pleaseConfirmMarkAsPaid: "Kérjük erősítse meg a fizetést:",
  successfullyPaid: "A számla sikeresen fizetettnek lett jelölve.",
  seeAllPaymentHistory: "Összes fizetési előzmény",
  seeAllEmailReminders: "Összes e-mail emlékeztető",
  seeAllInvoiceItems: "Összes tétel",
  withdrawPayment: "Fizetés visszavonása",
  payment: "Fizetés",
  paidImmediately: "A teljes összeg azonnal fizetve lesz a klinikán",
  dueDateForPayment: "Fizetési határidő",
  dueDate: "Határidő",
  invoiceCreated: "Sikeres számla létrehozás",
  deleteInvoice: "Számla törlése",
  withdrawReadyForPayment: "'Fizetésre kész' visszavonása",
  insuranceTitle: "Részszámla",
  payLater: "Fizetés később",
  invoiceNumber: "Számlaszám",
  invoiceSearchCategory: "Keresési kategória",

  // MainDoctor
  welcome: "Üdvözöljük",
  changeClinic: "Átváltás másik rendelőre",
  selectNone: "Egyik se",
  selectActiveClinic: "Aktuális rendelő kiválasztása",
  as: " {0}ként",
  activeClinic: "Aktuális rendelő",
  pleaseSelectActiveClinic:
    "Kérem, válassza ki a rendelőt, ahol dolgozni szeretne",
  noClinicAssigned: "Egy rendelő sincs Önhöz rendelve.",
  pending:
    "Függőben lévő kérése van {0} felhasználótól, hogy csatlakozzon {1} nevű rendelőhöz {2}ként",
  pendingRequest: "Függőben lévő felkérés",
  employmentRequestAccepted: "Felkérés elfogadva",
  noReservaionsOnThisDay: "Ezen a napon nincsenek foglalások és műszakok.",
  noShiftsOnThisWeek: "Ezen a héten nincsenek műszakok.",
  newShift: "Új műszak",
  createShift: "Műszak felvétele",
  noReservationsFound: "Nincs foglalás a megadott feltételekkel",
  pleaseCheckTheLocation: "Kérem, jelölje be a helyszínt a térképen.",
  owners: "Gazdák",
  skipForNow: "Folytatás később",
  addItLater: "Később szeretné hozzáadni?",
  setItLater: "Később szeretné beállítani?",
  setPinNumber: "PIN kód beállítása",
  noOrganizationYet: "Még nincs létrehozva organizáció",
  noClinicYet: "Még nincs létrehozva klinika",
  addFirstOrganization: "Hozza létre első organizációját",
  pendingInvitations: "Függőben lévő meghívók",

  // Management
  fees: "Díjak",
  country: "Ország",
  chooseCountry: "-- Válasszon országot --",
  baseFeeForClinic: "Rendelők alapdíja",
  baseFeeForEmployee: "Dolgozók havi alapdíja",
  baseFeeForVet: "Állatorvosok havi alapdíja",
  baseFeeForAssistant: "Asszisztensek havi alapdíja",
  baseFeeForReceptionist: "Recepciósok havi alapdíja",
  appointmentFee: "Időpontfoglalások díja",
  medicalRecordFee: "Kórlap díja",
  smsFee: "SMS díja",
  inventoryUsageFee: "Raktár használati díja",
  currencyValid:
    "A valutának muszáj két számjegyre kerekített értéknek lennie.",
  selectedCurrency: "A választott ország valutája: {0}",
  editDiscounts: "Kedvezmények szerkesztése",
  discounts: "Kedvezmények",
  existingDiscounts: "Jelenlegi kedvezmények",
  resetSettings: "Alaphelyzet",
  freeSubscription: "Ingyenes előfizetés",
  active: "Aktív",
  originalPrice: "Eredeti ár",
  discountedPrice: "Kedvezményes ár",
  warningSaveDiscount:
    "Az előfizetés módosításával a kiválasztott klinika költségszámítása azonnal megváltozik.",
  warningSaveCountryFees:
    "A tarifák módosításával az érintett klinikák költségszámítása azonnal megváltozik.",
  globalSettings: "Beállítások",
  policies: "Szerződések",
  unemployedWorkerLoginEnabled:
    "Dolgozói bejelentkezés engedélyezése munkaviszony nélküli felhasználók számára",
  existingPrivacyPolicies: "Létező adatvédelmi irányelvek",
  createPrivacyPolicy: "Adatvédelmi irányelvek létrehozása",
  editPrivacyPolicy: "Adatvédelmi irányelvek szerkesztése",
  languages: "Nyelvek",
  version: "Verzió",
  grammarChange: "Csak nyelvtani módosítás.",
  infoNoNewVersion:
    "Nem fog új verzió keletkezni, ha be van jelölve ez a mező.",
  successfulSave: "Sikeres mentés.",
  addPolicy: "Irányelv hozzáadása",
  editPolicy: "Irányelv módosítása",
  deletePolicy: "Irányelv törlése",
  deletePolicyConfirmation: "Biztosan ki akarja törölni ezt az irányelvet?",
  warningDeletePolicy:
    "A létező nyilatkozatok archiválódnak az esetleges jogviták rendezésének érdekében!",
  privacyPolicy: "Adatvédelmi irányelvek",
  privacyPolicyIncompleteWarning:
    "Előfordulhat, hogy az alábbi adatvédelmi nyilatkozat hiányos vagy fontos információk hiányoznak belőle. Kérjük, vegye figyelembe, hogy itt közölt információk várhatóan nem véglegesek, és módosításoknak lehetnek kitéve.",
  manageCookies: "Sütik kezelése",
  gdprConsent: "GDPR Hozzájárulás",
  generalTermsAndConditions: "Általános Szerződési Feltételek",
  dataProcessingTermsAndConditions: "Adatfeldolgozási Feltételek",
  policyStatement: "Nyilatkozat",
  insuranceCompany: "Biztosító",
  insuranceCompanies: "Biztosítók",
  fax: "Fax",
  paymentTerms: "Fizetési feltételek",
  users: "Felhasználók",
  profilePicture: "Profilkép",
  petProfilePicture: "Állat profilkép",
  clinicLogo: "Logó",
  facebookId: "Facebook ID",
  facebookEmail: "Facebook email",
  googleId: "Google ID",
  googleEmail: "Google email",
  ipAddress: "IP-cím",
  userAgent: "User agent",
  gdprExtractSentSuccessfully: "Kivonat sikeresen elküldve",
  sendExtractToUser: "Kivonat küldése a felhasználónak",
  changePassword: "Jelszó módosítása",
  serviceFees: "Szolgáltatási díjak",
  customFees: "Egyedi díjak",
  minimalChargeValue: "Minimális levonható összeg",
  paymentDate: "Fizetés ideje",
  allOrganizations: "Összes szervezet",

  // MedicalRecord
  open: "Megnyitás",
  examination: "Vizsgálat",
  dontHaveAccessToMedicalRecord: "Nincs hozzáférése ehhez a kórlaphoz!",
  petsMedicalRecord: "{0} kórlapja",
  unknownMedicalRecord: "Ismeretlen kórlapja",
  petOwner: "Állat tulajdonosa",
  petName: "Állat neve",
  anamnesis: "Anamnézis",
  physicalExamination: "Fizikális vizsgálat tünetei",
  diagnostics: "Műszeres diagnosztika",
  diagnosis: "Diagnózis",
  treatment: "Kezelés",
  recommendation: "Ajánlás",
  prognosis: "Prognózis",
  certificate: "Igazolás",
  referral: "Beutalás",
  changeIndicator: "Kijelző váltása",
  weights: "Súlyok",
  vaccines: "Vakcinák",
  medicines: "Gyógyszerek",
  history: "Előzmények",
  diagnoses: "Diagnózisok",
  differentialDiagnoses: "Differenciáldiagnózisok",
  treatments: "Kezelések",
  sign: "Aláír",
  signCorrection: "Javítás aláírása",
  openForCorrection: "Megnyitás javításra",
  correction: "Javítás",
  signedBy: "Aláírva",
  correctionSignedBy: "Javítás aláírva",
  signed: "Aláírva",
  correctionSigned: "Javítás aláírva",
  signMedicalRecord: "Kórlap aláírása",
  signer: "Aláíró",
  signText:
    "Ha aláírja {0} kórlapját, a kórlap véglegessé válik, és csak olvasható lesz.",
  addNewInfo: "Új információ hozzáadása",
  weight: "Súly",
  chooseFromWithDots: "Válasszon a listából",
  close: "Bezár",
  warning: "Figyelmeztetés",
  error: "Hiba",
  itemsAtNegativeStock:
    "A raktár feltöltése valószínűleg elmaradt, és emiatt egyes tételek készlete negatív értéket vesz fel.",
  invoice: "Számla",
  cashInvoice: "Készpénzfizetési számla",
  createdBy: "Létrehozta",
  created: "Létrehozva",
  chronicDiseases: "Krónikus betegségek",
  chronicDiseaseHistoryAdded: "{0} regisztrálva.",
  chronicDiseaseHistoryRemoved: "{0} eltávolítva.",
  connectedPrescriptions: "E-receptek",
  addInventoryItems: "Eszközök hozzáadása",
  addServiceFees: "Szolgáltatások hozzáadása",
  usedItems: "Felhasznált eszközök",
  usedFees: "Használt díjak",
  usedItemsAndFees: "Felhasznált eszközök és egyéb díjak",
  noServiceFeesAdded: "A listához még nem lett hozzáadva szolgáltatás.",
  medicalRecordHistory: "Kórlap történet",
  hematology: "Hematológia",
  biochemistry: "Biokémia",
  parameters: "Paraméterek",
  conventionalUnits: "Mértékegység",
  referenceRange: "Referencia értékek",
  result: "Eredmény",
  costs: "Költségek",
  inProgress: "Kitöltés alatt",
  notStarted: "Kitöltetlen",
  otherInventoryItems: "Egyéb raktári elemek",
  deleteRelatedItems: "Kapcsolódó elemek törlése",
  displayPetOverview: "Összefoglaló adatok megjelenítése",
  followUp: "Egyéb",
  createInvoice: "Számla létrehozása",
  addDocuments: "Dokumentumok hozzáadása",
  addFiles: "Fájlok hozzáadása",
  fileTooLarge: "A fájl túl nagy. A legnagyobb megengedett fájlméret 10 MB.",
  mailedTo: "Elküldve",
  inventoryItems: "Raktár tételek",
  searchInventoryItems: "Raktári tételek keresése",
  searchFees: "Szolgáltatások keresése",
  medicalRecordId: "Kórlap azonosító",
  resetTreatmentDescriptions: "Kezelés leírások visszaállítása",
  contentNotAvailable: "Nem elérhető tartalom.",

  // Prescription
  ePrescription: "E-Recept",
  pharmacy: "Gyógyszertár",
  prescription: "Recept",
  prescriptions: "Receptek",
  newPrescription: "Új recept",
  prescriptionItem: "Recept tartalma",
  addItems: "Hozzáadás",
  product: "Termék",
  substance: "Hatóanyag",
  barcode: "Vonalkód",
  formulation: "Formula",
  pharmaceuticalForm: "Gógyszer formátuma",
  typeOfPacking: "Kiszerelés típus",
  strength: "Erősség",
  manufacturer: "Gyártó",
  notAddedYet: "A recept még üres.",
  notPrescribable: "Ezt a készítményt nem lehet felírni.",
  withdrawalDate: "Visszavonás dátuma",
  details: "Részletek",
  packages: "Kiszerelések",
  packagingQuantity: "Kiszerelés darabszáma",
  ddd: "Meghatározott napi adag (DDD)",
  noDDD: "Nincs meghatározott napi adag.",
  noManufacturer: "A gyártó nem ismert.",
  addToPrescription: "Hozzáadás a recepthez",
  dosage: "Adagolás",
  instructions: "Használati utasítás",
  dose: "Adag",
  unit: "Egység",
  period: "Periódus",
  method: "Bevétel módja",
  addNewInterval: "Új időszak hozzáadása",
  expeditionInterval: "Időtartam",
  expeditionIntervalUnit: "Időegység",
  timeInterval: "Időtartam",
  timeIntervalUnit: "Időegység",
  waitingPeriods: "Karantén beállítások",
  mustBeBetween: "A számnak egésznek kell lennie, {0} és {1} között.",
  days: "napig",
  Days: "Napig",
  days2: "nap",
  Days2: "Nap",
  dayOrDays: "Nap",
  limit: "Limit",
  redemption: "Kiváltás",
  expirationDate: "Lejárati idő",
  redemptionPeriod: "Kiváltási időszak",
  firstRedemption: "Első kiváltási időszak",
  latestRequestedTimeForDispensing: "Utolsó kiváltás dátuma",
  latestRequestedTimeForDispensingDate: "Első kiváltás utolsó dátuma",
  latestRequestedTimeForDispensingDescription:
    "Az első visszavonás korlátozott érvényességének jelzésére használható. Ha a vény ezen időpontig nem került kiadásra, a teljes recept érvénytelenné válik.",
  finishPrescription: "Recept véglegesítése",
  allPharmacies: "Összes gyógyszertár",
  specificPharmacy: "Konkrét gyógyszertár",
  homeDeliveryFromSpecificPharmacy: "Házhozszállítás konkrét gyógyszertárból",
  municipality: "Község",
  chargedAutomatically: "Automatikus",
  chargedLater: "Később",
  notEnoughFields: "Nincs elég bemenet a kereséshez.",
  forPeriod: "{0} napig",
  addSpecificPharmacy: "Gyógyszertár kiválasztása",
  production: "Előállítás",
  delivery: "Átvétel",
  receiver: "Fogadó",
  verification: "Megerősítés",
  continue: "Folytatás",
  backToPrescription: "Vissza a recepthez",
  signature: "Aláírás",
  selectPharmacy: "Gyógyszertár választása",
  instructionLanguage: "Utasítások nyelve",
  unset: "Nincs beállítva",
  swedishCitizen: "Svéd állampolgár",
  swedishOrganisation: "Svéd szervezet",
  foreignCitizen: "Külföldi személy",
  organisationName: "Szervezet neve",
  organisationId: "Cégjegyzékszám",
  animalType: "Állat típusa",
  pleaseSelectIfNotSwedish: "Válasszon, ha nem svéd",
  quantityUnit: "Mértékegység",
  packageContent: "Csomag tartalma",
  licenseInformation: "Licenc információ",
  licenseId: "Licencazonosító",
  licenseProduct: "Licencelt készítmény",
  supplementaryInformation: "Kiegészítő információ",
  dosageForm: "Adagolási forma",
  pharmacyGLN: "Gógyszertár GLN kódja",
  createLicensedMedication: "Licencelt gyógyszer készítése",
  oneYearInterval: "Egy évnyi intervallum lehet.",
  chooseAPharmacy: "Válasszon egy gyógyszertárat",
  pleaseChoosePackages: "Adjon a recepthez legalább egy csomagot.",
  pleaseFillInstructions: "Kérem, töltse ki az utasításokat.",
  tooManyCharactersEnteredToInstructions:
    "Túl sok karakter került beírásra az instrukciókba.",
  tooManyCharactersEntered: "Túl sok karakter került beírásra.",
  withdraw: "Visszavonás",
  animalCarerInformed: "Tulajdonos tájékoztatva",
  onlyTwoFieldsAllowed: "Csak két mező kitöltése megengedett.",
  commentToPharmacist: "Megjegyzés",
  mandatoryIfLicenseIdProvided: "Kötelező, ha az azonosító adott.",
  groupCode: "Csoportazonosító",
  searchCommodity: "Kereskedelmi termék keresése",
  businessCompany: "Gyártó",
  atcCode: "ATC kód",
  productType: "Terméktípus",
  marketed: "Piaci elérhetőség",
  unMarketed: "Nem elérhető",
  drugClass: "Gyógyszerosztály",
  nplId: "NPL azonosító",
  nplPackId: "NPL csomagazonosító",
  nvn: "Nvn (VARA)",
  allProduct: "Minden termék",
  allGroup: "Minden kategória",
  quantityLimit: "Mennyiség",
  prescriptionDrug: "Vényköteles",
  lactose: "laktóz",
  latex: "latex",
  showOnlyMarketed: "Piacon elérhető termékek mutatása",
  showOnlyUnMarketed: "Piacon nem elérhető termékek mutatása",
  withdrawn: "Visszavonva",
  chooseASpecificPharmacy: "Válasszon gyógyszertárat",
  searchLicenseProduct: "Licencelt termék keresése",
  searchApprovedProducts: "Engedélyezett termékek",
  searchNotApprovedProducts: "Nem engedélyezett termékek",
  receiverPharmacy: "Fogadó gyógyszertár",
  none: "Egyik sem",
  cancelledPrescriptions: "Visszavont receptek",
  prescriptionsWithWarnings: "Figyelmeztetéses receptek",
  failedPrescriptions: "Hibás receptek",
  cancelledBy: "Visszavonta",
  requestedBy: "Kérvényezte",
  preview: "Előnézet",
  totalAmount: "Teljes mennyiség",
  draft: "piszkozat",
  dosageInfo:
    "Az adagolási mezők használata opcionális, viszont legalább egy kitöltése kötelező. Az előnézetben megtekintheti a végleges instrukciót.",
  defaultFeeToolTip:
    "Alapértelmezett receptszolgáltatási díj {0} SEK, mely a klinika beállításai között módosítható.",
  successfulPrescription: "A recept beküldése sikeres volt.",
  warningPrescription: "A recept beküldése figyelmeztetésekkel sikeres volt.",
  failedPrescription: "A recept beküldése sikertelen volt.",
  cancellationDetails: "Visszavonás részletei",
  statusOfCancellation: "Visszavonás állapota (válasz és kód)",
  cancellationId: "Lemondási azonosító",
  causeOfCancellation: "Visszavonás oka",
  partnerPharmacies: "Partner gyógyszertárak",
  acceptedPrescriptions: "Sikeres receptek",
  accepted: "Elfogadva",
  notAccepted: "Elutasítva",
  addNewName: "Új név",
  pinWarning:
    "PIN kód nincs beállítva. Recept kiállítás előtt a profil beállításokban hozzon létre egy új PIN kódot.",
  treatmentPurpose: "Kezelés célja",
  validUntil: "Érvényesség",
  otherSettings: "Egyéb beállítások",
  validUntilToolTip:
    "Antimikrobális készítmény: a kiállítástól számított 5 napig érvényes. Papír és elektronikus recept: kiállítástól számított egy évig érvényes.",
  notAntimicrobial: "Nem antimikrobiális",
  therapyShortHelpText: "beteg állatok kezelése",
  prophylaxisShortHelpText: "megelőzés",
  metaphylaxisShortHelpText: "fertőzés terjedésének megelőzése állatoknál",
  prophylaxisLongHelpText:
    "gyógyszer kiállítása állatnak vagy állatoknak a betegség klinikai tüneteinek megjelenése előtt, a kialakulásának megelőzésére vagy a fertőzés megállítására.",
  metaphylaxisLongHelpText:
    "gyógyszer kiállítása állatnak vagy állatoknak a betegség klinikai tüneteinek megjelenése után, a beteg állatok kezelésére és a további, - a ferőzött egyedekkel kapcsolatba került - állatok megfertődőzésének megelőzésére.",
  forFurther: "folytatva",
  everyOtherWeek: "Every other week",
  everyOtherDay: "Every other day",
  choose: "Használ",
  backToSearch: "Vissza a kereséshez",
  productAddedSuccessfully: "Sikeresen hozzáadta {0}-t a recepthez.",

  // Tasks
  task: "Teendő",
  tasks: "Teendők",
  newTask: "Új teendő",
  editTask: "Teendő szerkesztése",
  deleteTask: "Teendő törlése",
  complete: "Teljesített",
  completed: "Teljesített",
  onGoing: "Aktív",
  employee: "Alkalmazott",
  petOrOwner: "Állat vagy gazda",
  areYouSureDeleteTask: "Biztosan törölni szeretné a teendőt?",
  seeAllTasks: "Összes teendő",
  taskCreatedSuccessfully: "A teendőt sikeresen létrehoztuk.",
  taskSavedSuccessfully: "A teendőt sikeresen elmentettük.",
  taskDeletedSuccessfully: "A teendőt sikeresen töröltük.",
  addToTask: "Hozzáadás",
  saveAndCreate: "Mentés és létrehozás",

  // ModifyClinic
  basicInfo: "Alap adatok",
  contactInfo: "Elérhetőségek",
  bankAndBilling: "Bank és számlázás",
  position: "Munkakör",
  managers: "Menedzserek",
  vets: "Állatorvosok",
  assistants: "Asszisztensek",
  authorizedAssistants: "Autorizált asszisztensek",
  receptionists: "Recepciósok",
  employeeAlreadyAdded:
    "A dolgozó már a rendelőben dolgozik egy másik beosztásban.",
  modifyClinic: "Rendelő módosítása",
  inventory: "Raktár",
  calendar: "Naptár",
  clinicStatistics: "Statisztika",
  reservationTypes: "Foglalás típusok",
  notifications: "Értesítések",
  smsWarning: "Az SMS értesítésekért külön díjat számítunk fel.",
  sendRemindersVia: "Időpontemlékeztetők küldése {0} az állattulajdonosoknak",
  sendRemindersViaEmail: "emailben",
  sendRemindersViaSms: "SMS-ben",
  inventoryHandling: "Raktárkezelés",
  trackInventoryStock: "Raktárkészlet követése",
  inventoryTrackingDisableText:
    "Ez a művelet kinulláza az összes raktáron lévő tétel mennyiségét. A mennyiségek eltűnnek, de az árazás és a kezelések továbbra is megmaradnak.",
  decline: "Elutasít",
  accept: "Elfogad",
  inventoryTrackingEnabled: "Raktárkészlet követése bekapcsolva",
  inventoryTrackingDisabled: "Raktárkészlet követése kikapcsolva",
  clinicHasNoReservationTypes:
    "A rendelőnek még nincsnenek időpontfoglalási típusai.",
  affiliatedSwedishPharmacies: "Partner gyógyszertárak",
  addPharmacy: "Új gyógyszertár",
  order: "Sorrend",
  basicInfoTooltip: "Alap rendelő beállítások. Rendelő nevének módosítása.",
  basicInfoContactTooltip:
    "Alap rendelő beállítások. A rendelő elérhetőségeinek módosítása.",
  collaboratorsToolTip:
    "Rendelő alkalmazottainak beállításai. Asszisztensek, állatorvosok, recepciósok, és menedzserek felvétele, törlése.",
  reservationTypesToolTip:
    "Rendelő foglalás fajtái, melyek segítségével foglalhatnak időpontokat a rendelő alkalmazottai és a gazdák, naptáron keresztül.",
  feesToolTip:
    "Rendelő általános díjai. Itt lehet beállítani az alapértelmezett árakat, de később azok minden munkafolyamatban testreszabhatók.",
  prescriptionFeeToolTip:
    "A receptdíj használatához ki kell választani egy Bankgiro vagy Plusgiro fiókot, melyet a klinika beállításainál lehet megadni.",
  notificationToolTip:
    "Emlékeztető beállítás gazdák számára. A rendszer e-mail vagy SMS emlékeztetőt küld foglalások előtt.",
  inventoryToolTip:
    "Rendelő raktárbeállításai. Raktár követés funkció bekapcsolásával a kezelések automatikusan levonják az elhasznált eszközöket a készletből.",
  clinicPharmacyTooltip:
    "Receptdíj beszedhető ezekben a gyógyszertárakban, kölcsönös megállapodás alapján.",
  deletedProfile: "Törölt profil",
  notificationOnReservationCancel:
    "{0} küldése a gazdának foglalás törlés esetén",
  notificationOnReservationCancelSms: "SMS",
  notificationOnReservationReschedule:
    "{0} küldése a gazdának a foglalás módosítása esetén",
  notificationOnReservationRescheduleSms: "SMS",
  calendarSettings: "Naptár beállítások",
  calendarSettingsToolTip:
    "Naptár kezdő és záró időpontjának beállítása. Alapbeállítás: 08:00–18:00.",
  confirmEmployeeInvitation: "Biztosan szeretne meghívót küldeni a {0} címre?",
  confirmEmployeeRequestDeletion:
    "Biztosan szeretné visszavonni {0} meghívását?",
  inviteEmployee: "Munkatárs meghívása",
  trialDiscountPercent: "Próbaidőszak kedvezmény",
  trialPeriodDays: "Próbaidőszak hossza",
  trialEndReminderBeforeDays: "Emlékeztető a próbaidőszak végéről",
  daysBefore: "nappal korábban",
  discount: "kedvezmény",
  siteManagers: "Menedzserek",
  siteManager: "Menedzser",
  permissions: "Engedélyek",
  fullAccess: "Teljes hozzáférés",
  config: "Config",
  countries: "Országok",
  activeCountries: "Aktív országok",
  inactiveCountries: "További országok",
  paymentAndBilling: "Fizetés és számlázás",
  paymentSystem: "Fizetési rendszer",
  secretKey: "Titkos kulcs",
  billingSystem: "Számlázórendszer",
  clientId: "Kliens azonosító",
  clientSecret: "Kliens titok",
  testInvoices: "Tesztszámlák",
  testInvoice: "Tesztszámla",
  createTestInvoice: "Tesztszámla létrehozása",
  noTestInvoiceCreatedYet: "Még nincs tesztszámla létrehozva.",
  testInvoiceWarning:
    "Ha egy éles környezet hitelesítő adatait adtad meg, kérlek, ne felejtsd el visszavonni a létrehozott számlát a számlázási rendszeren belül.",
  insuranceInvoiceSettings: "Biztosítónak kiállított számla beállításai",
  systemIntegrations: "Rendszerintegrációk",
  systemIntegrationsAllWorking: "Az összes rendszerintegráció működik",
  discountFree: "Ingyenes",
  discountCustom: "Egyéni",
  untilWithdrawn: "visszavonásig",
  remoteAccess: "Távoli hozzáférés",
  foreignClinicWarning:
    "Az oldal menedzsereként egy idegen klinikához férsz hozzá. Ügyelj rá, hogy ne hajts végre nem kívánt változtatást!",
  leaveClinic: "Klinika elhagyása",

  // NavSiteManager
  SITE_MANAGER_SETTINGS: "Beállítások",
  BILLING: "Számlázás",
  VET_DOCUMENTS: "Orvosi dokumentumok",
  INSURANCE: "Biztosítás",
  USERS: "Felhasználók",
  FEEDBACKS: "Visszajelzések",
  CLINICS: "Klinikák",
  ANALYTICS: "Analitika",

  // Usage statistics
  statisticsTitle: "Statisztika",
  statisticsAndPayment: "Statisztika és fizetés",
  generalData: "Általános adatok",
  chooseAMonth: "Hónap kiválasztása",
  chooseMonth: "-- Válasszon egy hónapot --",
  priceOfUsedItems: "Felhasznált segédeszközök összköltsége",
  priceOfAppliedFees: "Szolgáltatások költsége",
  statisticsErrorMsg: "Nincs elérhető statisztikai adat.",
  inventoryStockUsageFee: "Összes raktárhasználati díj",
  sumFeeAtMoment: "Aktuális költségek a hónapban",
  forecastFeeSum: "További prognosztizált költségek a hónapban",
  summaryOfFees: "Teljes összeg",
  noPrevDataFound: "Nincs elérhető adat",
  monthlySubscriptionHistory: "Havi előfizetési előtörténet",
  subscriptionPackage: "Előfizetéscsomag",
  actual: "Aktuális",
  currentlyActive: "Jelenleg aktív",
  feeName: "Költség neve",
  count: "Darabszám",
  unitPrice: "Egységár",
  subtotal: "Részösszeg",
  usageStatistics: "Statisztika",
  clinicCosts: "Klinika költségei",
  organizationCosts: "Szervezet költségei",
  analyticsEventType: "Esemény típusa",
  user: "Felhasználó",
  activityLogs: "Tevékenységnapló",
  ownedClinics: "Saját klinikák",
  workplaces: "Munkahelyek",
  clinicsVisited: "klinika meglátogatva",
  lastActivity: "Utolsó tevékenység",

  // Organization settings
  modifyOrganization: "Szervezet módosítása",
  editOrganizationNamePlaceholder: "A szervezet neve",
  editBusinessNamePlaceholder: "A szervezetet működtető vállalkozás neve",
  editVATNumberPlaceholder: "A szervezet adószáma",
  editRegistrationNumberPlaceholder: "A szervezet regisztrációs száma",
  clinics: "Rendelők",
  createNewClinic: "Új rendelő létrehozása",
  createNewOrganization: "Új szervezet létrehozása",
  manager: "Menedzser",
  creditCard: "Bankkártya adatai",
  creditCardNumber: "Bankkártya száma",
  fortnoxInvoiceSystem: "Fortnox számlázórendszer",
  state: "Állapot",
  integrated: "Összekapcsolva",
  notIntegrated: "Nincs összekapcsolva",
  authenticationNeeded: "Hitelesítés szükséges",
  authenticate: "Hitelesítés",
  fortnoxIntegration: "Fortnox összekapcsolás",
  addIntegration: "Összekapcsolás",
  fortnoxIntegrationSuccess:
    "Sikeresen összekapcsolódott a Fortnox rendszerével.",
  disableFortnoxConfirmation:
    "Biztosan törölni szeretné a Fortnox kapcsolatot?",
  fortnoxRedirect:
    "Át fogjuk irányítani a Fortnox weboldalára. Kérjük, kövesse a megadott lépéseket az integráció befejezéséhez.",
  editData: "Adatok szerkesztése",
  turnOff: "Kikapcsolás",
  fortnoxIntegrationCostWarning:
    "Az összekapcsolás aktiválása és igénybevétele kezelési költségekkel jár.",
  invalidData: "Érvénytelen adatok",
  settingsFailed: "Sikertelen beállítás",
  stripeIntegration: "Stripe összekapcsolás",
  stripeIntegrationCostWarning:
    "Az öszekapcsolás aktiválása és igénybevétele kezelési költségekkel jár.",
  publicKey: "Nyilvános kulcs",
  privateKey: "Privát kulcs",
  bankAndPlusGiroAccount: "Bankgirot és Plusgirot fiókok",
  bankgirot: "Bankgirot",
  plusgirot: "Plusgirot",
  prescriptionFee: "Receptfelírási díj",
  videoConsultationFee: "Videókonzultáció díja",
  basicInfoOrgToolTip:
    "A cég általános beállításai. A név egy tetszőlegesen választható megjelenített név, a cégnév a jogi személy hivatalos neve.",
  basicInfoOrganizationNameToolTip:
    "Egy tetszőlegesen választható, csak a GlobalVet alkalmazásban használt név.",
  basicInfoBusinessNameToolTip: "A vállalkozás hivatalos neve.",
  basicInfoContactOrgToolTip:
    "A cég hivatalos elérhetőségeinek beállításai. Elsőként az elődleges elérhetőségeket használja a rendszer.",
  managersOrgToolTip:
    "A cég menedzserei, akik a cég minden adatát módosíthatják.",
  giroOrgToolTip:
    "Bankgiro vagy Plusgiro fiók szükséges a svéd receptbeküldéshez, abban az esetben amikor a receptet egy partnergyógyszertárban fizeti ki az ügyfél.",
  creditCardOrgToolTip:
    "A cég bankkártyaadatai. A GlobalVET szolgáltatásainak díjait erre a kártyára terheli majd a rendszer.",
  stripeOrgToolTip:
    "A rendszer támogatja a Stripe használatát az online fizetésekhez. Ha a cég rendelkezik Stripe fiókkal, itt összekötheti az alkalmazással.",
  fortnoxToolTip:
    "A rendszer támogatja a Fortnox használatát a számlázáshoz. Ha a cég rendelkezik Fortnox fiókkal, itt összekötheti az alkalmazással.",
  statisticsToolTip:
    "A rendszer használatáról készült statisztika és fizetési előzmények.",
  treatmentToolTip:
    "Az itt felvett kezeléseket használja a cég minden rendelője. Egy kezelés hozzárendelhető egy konkrét fajhoz, vagy általánosan is kezelhető.",
  inventoryOrgToolTip:
    "Az itt felvett tételek a cég összes klinikájának raktárába bekerülnek. A beállított árak itt alapértelmezettek, klinikánként testreszabhatóak. A raktárkészlet nyilvántartását szintén a klinika raktárában lehet menedzselni.",
  addFee: "Díj hozzáadása",
  createNewServiceFee: "Új szolgáltatási díj létrehozása",
  addPaymentMethod: "Fizetési mód hozzáadása",
  supportedPaymentMethods: "Támogatott fizetési módok",
  noPaymentMethods: "A klinikának nincs támogatott fizetési módja még.",
  createCodeInFortnox: "Kód létrehozása a Fortnoxban is",
  officialLanguage: "Hivatalos nyelv",
  syncWithFortnox: "Fortnox szinkronizálás",
  roundingSettings: "Kerekítés és áfaszámolás",
  roundingSettingsTooltip:
    "Ha GlobalVethez kapcsolt külső számlázót (Fortnox vagy Számlázz.hu) is használ, akkor kérjük, győződjön meg róla, hogy ugyanazokat a kerekítési szabályokat állítja be itt, mint amiket a számlázó is használ!",
  roundingStrategyItem: "Számlatétel kerekítése",
  roundingStrategyTotal: "Számlavégösszeg kerekítése",
  vatCalculationStrategy: "ÁFA számítás",

  // OwnerClinicListPage
  cannotRetriveOrganizationData: "Nem sikerült betölteni a szervezet adatait.",
  cannotRetrivePetData: "Nem sikerült betölteni az állat adatait.",
  clinicListByOrganization: "Rendelők listája",
  reserveAppointment: "Időpont foglalása",
  organizations: "Szervezetek",
  chooseOrganization: "-- Válasszon szervezetet --",
  chooseClinic: "-- Válasszon rendelőt --",
  chooseReservationType: "-- Válasszon foglalási típust --",
  choosePet: "-- Válasszon állatot --",
  chooseAPet: "Válasszon állatot",
  next: "Tovább",
  previous: "Vissza",
  unselected: "Nincs kiválasztva",

  // OwnerCalendarPage
  reason: "Indok",
  writeReason: "Írja le az indokot...",

  // OwnerInvoicePage
  invoiceToPay: "Befizetendő számlák",
  creationOfInvoice: "Számla kelte",
  invoices: "Számlák",
  pdf: "PDF",
  paid: "Fizetve",
  infoOnlinePaymentDisabled:
    "Ennél a rendelőnél nem lehet online számlát befizetni.",
  noInvoicesToPay: "Nincsenek befizetendő számlák.",
  noInvoices: "Nincsenek számlák.",

  // WorkerCalendarPage
  back: "Vissza",
  chosenDate: "Választott dátum",
  chooseADoctor: "Választott orvos",
  chooseDoctor: "-- Válasszon orvost --",
  description: "Leírás",
  writeSymptoms: "Írja le a tüneteket...",
  reserve: "Foglalás",
  reservationError: "Erre az időpontra nem lehet foglalni.",
  reservationInProgress: "Foglalás folyamatban",
  browseNewTimeSlot: "Új időpont keresése",
  newReservation: "Új időpont foglalása",
  deletedItsProfile: " törölte a profilját.",
  collaborator: "Alkalmazott",
  createShiftFor: "Műszakhoz tartozó alkalmazott",
  reservationDetails: "Foglalás részletei",
  reject: "Elutasít",
  showReservations: "Foglalások mutatása",
  showShifts: "Műszakok mutatása",
  shifts: "Műszakok",
  modifyShiftFor: "{0} műszakjának módosítása",
  reservations: "Foglalások",
  listView: "Listanézet",
  doctorView: "Orvosi nézet",
  reScheduleAppointment: "Foglalás áthelyezése",
  reScheduleToolTip: "Keressen a naptárban egy új időpontot.",
  reSchedule: "Áthelyezés",
  confirmReSchedule: "Áthelyezés megerősítése",
  startLaterThanEnd: "Végidőpont nem lehet korábban a kezdetnél.",

  // Symptoms
  symptom: "Tünet",
  symptoms: "Tünetek",
  searchOrCreateSymptom: "Tünet keresése vagy új hozzáadása",

  // Profile
  savedSuccessfully: "Sikeres mentés",
  passwordChangedSuccessfully: "Sikeres jelszóváltás",
  changeMyPassword: "Jelszó megváltoztatása",
  setDefaultLanguage: "Alapértelmezett nyelv beállítása",
  notSpecified: "Nincs kiválasztva",
  contacts: "Elérhetőségek",
  language: "Nyelv",
  upload: "Feltöltés",
  security: "Biztonság",
  linkedAccounts: "Összekapcsolt fiókok",
  googleAccount: "Google fiók",
  facebookAccount: "Facebook fiók",
  noLinkedAccounts: "Nincs összekapcsolt fiók.",
  accountLinkedSuccessfully: "A fiók összekapcsolása sikeresen megtörtént.",
  idNumber: "Személyi szám",
  pinCodeMustBeConfirmed: "A PIN kód használata előtt meg kell erősíteni azt.",
  codeIsSentToYourMobile:
    "A megerősítő kódot elküldtük az elsődlegesen használt mobiltelefonszámra.",
  addNewPin: "Új PIN kód hozzáadása",
  verifyPin: "PIN kód megerősítése",
  userHasPinCode: "Önnek már van beállítva PIN kód.",
  profilePictureIsPublic: "A profilkép minden felhasználó számára nyilvános.",
  deleteProfile: "Profil törlése",
  deleteProfileWarning:
    "A kért művelet nem visszavonható, a személyes adatok törlésre kerülnek a rendszerből. Kérjük, vegye figyelembe, hogy azoknak a klinikáknak, melyek korábban szolgáltatást nyújtottak Önnek, továbbra is lesz hozzáférésük bizonyos adatokhoz a számlázással és az állatok követhetőségével kapcsolatban.",
  deleteProfileWarningWorker:
    "A korábbi műveletek, amiket klinikai dolgozóként végrehajtott, a klinika többi dolgozója számára láthatók maradnak.",
  deleteProfileCredentials:
    "Kérjük, adja meg a belépési jelszavát a törlés megerősítéséhez:",
  deleteContactInformation: "Elérhetőség törlése",
  areYouSureDeleteContactInformation:
    "Biztosan törölni szeretné elérhetőségét?",
  profileBasicInfoToolTip:
    "Alap profil beállítások kezelése. A személyi szám lakcímhez kötött, változtatása új cím felvételével lehetséges.",
  profileContactToolTip:
    "E-mailek, címek és telefonszámok szerkesztése. Az elsődlegesen kiválasztott elérhetősége lesz a rendszerben használva.",
  profileVetRegNumberToolTip:
    "Hivatalos nemzeti állatorvosi regisztrációs számok kezelése. Bekerül majd a receptbe és a kórlapba.",
  vetRegNumberInfo:
    "Receptkiállításhoz szükséges az állatorvosi azonosító feltöltése.",
  vetRegNumbersEmpty:
    "Önnek jelenleg nincs megadva állatorvosi regisztrációs száma.",
  vetRegNumberDeleteConfirmation:
    "Biztosan ki szeretné törölni ezt az állatorvosi regisztrációs számot?",
  changesSavedSuccessfully: "A változtatások sikeresen el lettek mentve.",

  // ResetPassword
  PasswordRecoveryLinkNotValid: "A jelszóhelyreállítási link nem érvényes.",
  resetPassword: "Jelszó viszaállítása",
  resetPasswordEmailInfo:
    "Írja be a regisztrációhoz használt e-mail címét. A megadott e-mail címre küldjük a jelszóemlékeztetőt.",
  resetPasswordMobileInfo: "Írja be a regisztrációhoz használt telefonszámát.",

  // SearchResult
  ownerName: "Tulajdonos neve",
  addNewAppointment: "Új időpont hozzáadása",
  newAppointment: "Új időpont",
  newMedicalRecord: "Új kórlap",
  addNewMedicalRecord: "Új kórlap hozzáadása",
  periodDoesNotFitIntoShift: "A foglalás nem fér bele a választott műszakba.",
  pages: "oldal",
  openLastMedicalRecord: "Utolsó kórlap megnyitása",
  petHasNoMedicalRecords: "Ennek az állatnak még nincsenek kórlapjai.",
  total: "Összesen",
  results: "találat",

  // PhoneConfirmation
  phoneConfirmation: "Telefonszám megerősítése",
  verifyYourPhone: "Telefonszám megerősítése",
  confirmationCode: "Megerősítő kód",
  verificationCode: "Ellenőrző kód",
  confirmationLinkNotValid: "A megadott link érvénytelen.",
  invalidVerificationCode: "Érvénytelen megerősítő kód, próbálja újra.",
  confirmationCodeMustBeSixCharacters:
    "A megerősítő kód pontosan 6 karakterből áll.",
  phoneConfirmationSuccess: "Telefonszám sikeresen megerősítve.",
  resendVerificationCode: "Megerősítő kód újraküldése",
  phoneCodeSent: "Megerősítő kódot elküldtük a következő telefonszámra: ",

  // TreatmentPage
  dontHavePermissionToCreateTreatments:
    "NIncs jogosultsága kezelés létrehozására.",
  worngNameOrPrice: "Helytelen név vagy ár.",
  showAll: "Mindent mutat",
  useFilter: "Szűrő használata",
  chooseTheSpecies: "Faj kiválasztása",
  deleteTreatment: "Kezelés törlése",
  editTreatment: "Kezelés szerkesztése",
  nameSuggestionToolTip:
    "Itt a többi organizácó kezeléseinek elnevezéseiből lehet választani.",
  generateIntoMedicalRecordToolTip: "Ez a leírás a kórlapba is be fog kerülni.",
  backToTreatments: "Vissza a kezelésekhez",

  // WorkerClinicPage
  thWeek: "{0}. hét",
  noShiftOnThisDay: "Nincs műszak ezen a napon.",
  owner: "Tulajdonos",
  consultationType: "Konzultáció típusa",
  time: "Időpont",
  action: "Művelet",
  saveAsPDF: "Mentés PDF-ként",
  medicalRecord: "Kórlap",
  costCalculation: "Költség összegzés",

  // Add AddressOwnershipForm
  addAddress: "Cím hozzáadása",
  city: "Város",
  zip: "Irányítószám",
  postalCode: "Irányítószám",
  street: "Utca",
  county: "Megye",
  building: "Épület",
  addressLine1: "Utca és házszám",
  addressLine2: "Épület, emelet, ajtó, stb.",
  setPrimaryAddress: "Elsődleges cím beállítása",
  deleteAddress: "Cím törlése",
  deleteAddressConfirmation: "Biztosan ki szeretné törölni ezt a címet?",
  primaryAddressPromotionTextPart1: "Szeretné beállítani ",
  primaryAddressPromotionTextPart2: " címet elsődlegesnek?",
  primaryEmailPromotionTextPart1: "Szeretné beállítani ",
  primaryEmailPromotionTextPart2: " email címet elsődlegesnek?",
  primaryPhonePromotionTextPart1: "Szeretné beállítani ",
  primaryPhonePromotionTextPart2: " telefonszámot elsődlegesnek?",
  setAsPrimary: "Beállítás elsődlegesnek",
  setPrimaryEmail: "Elsődleges email cím beállítása",
  deleteEmail: "Email cím törlése",
  deleteEmailConfirmation: "Biztosan ki szeretné törölni ezt az email címet?",
  setPrimaryPhone: "Elsődleges telefonszám beállítása",
  deletePhoneNumber: "Telefonszám törlése",
  deletePhoneNumberConfirmation:
    "Biztosan ki szeretné törölni ezt a telefonszámot?",
  checkOnTheMap: "A jelölő segítségével véglegesítheti a címet.",
  iHaveVerifiedTheTimeZoneAndLocation:
    "Meggyőződtem róla, hogy a pozíció és az időzóna helyes.",
  timeZoneRequired: "AZ időzóna megadása kötelező.",

  // UserAddressForm
  newAddressDetails: "Új cím megadása",
  newAddressCheck: "Új cím ellenőrzése",
  checkSuccessful: "Sikeres címellenőrzés!",
  found: "Talált",
  chosen: "Kiválasztott",
  timeZone: "Időzóna",
  addressNotFound: "Nem sikerült a címet megtalálni!",
  locationDetectedByAddress: "A helyszínt sikeresen azonosítottuk.",

  // AddEmailOwnershipForm
  addEmail: "Email hozzáadása",
  email: "Email",
  emailExample: "pelda@vallalat.com",
  emailExampleName: "nev@company.com",
  yourEmail: "Az ön email címe",

  // Switch
  on: "Be",
  off: "Ki",

  // Modal
  closeModal: "Ablak bezárása",

  // Select
  pleaseChoose: "-- Kérem válasszon --",

  // AddPhoneOwnershipForm
  addPhoneNumber: "Telefonszám hozzáadása",
  phone: "Telefonszám",
  emailRequiredForPhone:
    "Legalább egy megerősített email cím szükséges a telefonszám hozzáadásához.",

  // Ownerships
  addresses: "Címek",
  availableAddresses: "Elérhető címek",
  address: "Cím",
  yourAddress: "Az ön címe",
  location: "Helyszín",
  emails: "Emailek",
  phoneNumbers: "Telefonszámok",
  phoneNumber: "Telefonszám",
  phones: "Telefonszámok",
  availablePhones: "Elérhető telefonszámok",
  emailNotConfirmedYet: "Email cím még nincs megerősítve.",
  atLeastOneEmailRequired: "Legalább egy email cím megadása szükséges.",
  primary: "Elsődleges",
  private: "Privát",
  public: "Nyilvános",
  contactType: "Típus",
  vat: "Adószám",
  vatNumber: "Adószám",
  vatAsTax: "ÁFA",
  phoneType: "Telefon típusa",
  phoneNotConfirmedYet: "A telefonszám még nincs megerősítve.",
  verificationCodeSentTo:
    "A megerősítő kódot elküldtük a következő telefonszámra: {0}.",
  verificationError: "Hibás megerősítés",
  primaryContactNotDeletable: "Elsődleges elérhetőséget nem lehet törölni. Vegyen fel egy újat, tegye meg elsődleges elérhetőséggé, majd törölje a régit.",

  // AddReservationTypeForm
  addReservationType: "Foglalási típus hozzáadása",
  createNewReservationType: "Új foglalási típus létrehozása",
  duration: "Időtartam (perc)",
  durationMustBePositive: "Az időtartamnak pozitív egész számnak kell lennie.",
  isReservableByOwnersQuestion:
    "Állat tulajdonosai is foglalhatják ezt a típust?",
  isReservableByOwners: "Online foglalás engedélyezése",
  isVideoConsultationAllowedQuestion:
    "Online videókonzultációként is foglalható ez a típus?",
  isVideoConsultationAllowed: "Videókonzultáció engedélyezése",
  onlineReservationIsNotAvailable:
    "Online foglalás nem elérhető ezen a klinikán.",

  // EditReservationTypeForm
  editReservationType: "Foglalási típus módosítása",

  // AddTreatmentForm
  addTreatment: "Kezelés hozzáadása",
  createNewTreatment: "Új kezelés létrehozása",
  treatmentName: "Kezelés neve",
  treatmentPrice: "Kezelés ára",
  chooseASpecies: "Válasszon egy fajt",
  treatmentNameMustNotBeEmpty: "A kezelés nevét kötelező kitölteni.",
  addTreatmentSuccess: "Kezeléshez sikeresen hozzáadva.",
  workCost: "Munkaköltség",
  basePrice: "Alapár",

  // AddUserForm
  addNew: "Hozzáad",
  addNewManager: "Menedzser hozzáadása",

  // BillingSection
  billing: "Számlázás",
  item: "Tétel",
  quantity: "Mennyiség",
  extraItems: "Extra tételek",
  extraItemsAndFees: "Extra tételek és díjak",
  addExtraItem: "Extra tétel hozzáadása",
  add: "Hozzáad",
  sum: "Végösszeg",
  taxNumber: "Adószám",
  sellItems: "Termékek eladása",
  accountingCode: "Könyvelési kód",
  accountingCodes: "Könyvelési kódok",
  chargeClinic: "Klinika fizettetése",

  // BanCardForm
  cardData: "Kártya adatai",

  // CategoryList
  categories: "Kategóriák",
  createNewCat: "Új kategória létrehozása",
  Vaccines: "Vakcinák",
  Medicines: "Gyógyszerek",

  // ChronicDiseasesModal
  alreadyRegisteredDiseases: "Eddig regisztrált krónikus betegségek",
  diseaseName: "Betegség neve",
  certified: "Hitelesítve",
  notCertified: "Nincs hitelesítve",
  confirmMessage:
    "A hitelesítés csak akkor teljesül, ha a betegséget egy állatorvos megerősíti a kórlapon.",
  certificationDate: "Hitelesítés dátuma",
  addNewChronicDisease: "Új krónikus betegség hozzáadása",

  // DeleteReservation
  AreYouSureDeleteAppointment: "Biztos, hogy lemondja az időpontot?",
  whyDoYouWantToRejectAppointment:
    "Kérem adja meg, hogy miért utasítja vissza ezt az időpontot.",
  confirm: "Megerősít",
  cancelAppointment: "Időpont lemondása",
  rejectAppointment: "Időpont elutasítása",
  rejectError: "Időpont törlése nem lehetséges.",
  cancelAppointmentSuccess: "Sikeresen lemondta az időpontot.",
  cancelAppointmentError:
    "Az időpont lemondása már nem lehetséges. Kérjük, vegy fel a kapcsolatot a klinikával.",

  // InventoryNavBar.tsx
  bracnhesInventory: "{0} / Raktár",
  home: "Főoldal",
  inventoryStatus: "Raktár állapota",
  statistics: "Statisztikák",

  // Treatments
  comment: "Megjegyzés",
  items: "Tételek",
  remove: "Eltávolít",
  saveAsNew: "Mentés másként",
  use: "Felhasznál",
  treatmentDescription: "Kezelés leírása",
  advices: "Tanácsok az állat gazdájának",
  suggestedMedicines: "Javasolt gyógyszerek",
  complications: "Komplikációk",

  // Inventory
  searchWithDots: "Keresés...",
  new: "Új",

  // OwnerCalendar
  toggleWeekends: "Hétvégék megjelenítése",
  prevWeek: "Előző",
  nextWeek: "Következő",
  appointment: "Időpont",
  createUnregisteredPet: "Regisztráció",
  theOwnerWillNotSee: "A gazda ezt nem fogja látni.",
  personalId: "Személyazonosító szám",
  personalIdFormatPlaceholder: "yyyymmddpppp",
  invalidFormat: "Érvénytelen formátum, elvárt formátum: {0}",
  iDontHavePersonalIdInThisCountry:
    "Nincs személyi azonosítóm az adott országban",
  doesntHavePersonalIdInThisCountry:
    "Nincs személyi azonosítója az adott országban",
  checkClinicRegistrationsNotificationMsg:
    "Úgy tűnik, hogy Ön már járt állatorvosi rendelőben, mielőtt regisztrált.",
  resolveClinicRegsBtnTitle: "Ellenőrzés",
  pendingClinicRegistrations: "Regisztrációk megerősítése",
  createAsNewPet: "Új állatként létrehoz",
  claimAsMe: "Elfogad",
  itWasntMe: "Nem én voltam",
  thereAreNoPendingClinicRegistrations:
    "Nincsenek megerősítésre váró regisztrációk.",
  iAcceptThatOrganizationWillReceiveMyData:
    "Elfogadom, hogy a szervezet hozzáférést kap a személyes adataimhoz és az állat kórelőzményeihez a kezelés elősegítésének érdekében.",
  noAvailableShiftsForThisReservationType:
    "Ehhez a foglalástípushoz nincsenek szabad időpontok.",

  // Video consultation
  videoConsultation: "Videókonzultáció",
  globalVetVideoConsultation: "GlobalVET videókonzultáció",
  usersInTheRoom: "Már a szobában lévő felhasználók:",
  enableMicrophone: "Mikrofon bekapcsolása",
  enableCamera: "Kamera bekapcsolása",
  noMicrophoneDetected:
    "A mikrofonja nem észlelhető. Kérjük győződjön meg róla, hogy a mikrofon megfelelően van csatlakoztatva, majd frissítse az oldalt, ha beszélni szeretne.",
  microphoneAccessDenied:
    "Mikrofon hozzáférés megtagadva! Kérjük ellenőrízze a böngészőbeállításokat.",
  microphoneWorks: "A mikrofonjának a várt módon kell működnie.",
  noWebcamDetected:
    "A webkamerája nem észlelhető. Kérjük győződjön meg róla, hogy a webkamera megfelelően van csatlakoztatva, majd frissítse az oldalt, ha szeretné, hogy mások is lássák.",
  webcamAccessDenied:
    "Webkamera hozzáférés megtagadva! Kérjük ellenőrízze a böngészőbeállításokat.",
  webcamWorks: "A webkamerának a várt módon kell működnie.",
  mediaErrorTooltip: "Kattintson további információkért",
  mediaErrorTitle: "A GlobalVET nem tudja használni ezt a médiaeszközt",
  mediaErrorWarningText:
    "Hiba történt a médiaeszköz beállítása során. Kérjük, próbálja meg az alábbi lépéseket a probléma megoldásához.",
  mediaErrorSuggestion1:
    "Zárja be azokat az alkalmazásokat, amelyek esetleg használják a kamerát",
  mediaErrorSuggestion2: "Zárja be, majd nyissa meg újra a böngészőt",
  mediaErrorSuggestion3:
    "Ha külső kamerát vagy mikrofont használ, válassza le, majd csatlakoztassa újra",
  mediaErrorSuggestion4: "Ellenőrizze az operációs rendszer média beállításait",
  permissionRequestTitle: "Engedély szükséges",
  permissionRequestText:
    'A folytatáshoz kattintson az "Engedélyezés" vagy "Tiltás" gombra a böngésző által megjelenített engedélykérő ablakban. Ennek az ablaknak a megjelenése eltérő lehet a különböző böngészőkben.',
  permissionRequestInfo:
    'Ha nem szeretné engedélyezni a mikrofonja vagy kamerája használatát, kattintson az "Tiltás" gombra. A videókonzultációhoz ebben az esetben is tud csatlakozni.',
  copyMeetingLinkCopiedToClipboard: "Konzultáció linkjének vágolapra másolása",
  meetingLinkCopiedToClipboard:
    "A konzultáció linkje a vágólapra lett másolva!",
  linkCopied: "Link másolva!",
  waitingForOtherParticipants:
    "Még nincsenek résztvevők. Kérjük, várjon, amíg a többiek csatlakoznak...",
  inviteToMeeting: "Mások meghívása",
  joinConsultation: "Csatlakozás a konzultációhoz",
  areYouSureToClose: "Biztosan bezárja a beszélgetést?",
  meetingEnded: "A beszélgetés befejeződött {0} idő alatt.",
  meetingDuration: "Időtartam: ",
  reconnectToMeeting: "Újracsatlakozás a beszélgetéshez",
  goBackToWaitingRoom: "Vissza a várószobába",
  meetingDeviceError: "Hardveres probléma",
  meetingGrantPermissions:
    "Kérem csatlakoztasson egy mikrofont, egy webkamerát, adja meg a szükséges engedélyeket, majd frissítse az oldalt.",
  reloadMeeting: "Oldal frissítése",
  addVideoConsultation: "Videókonzultáció hozzáadása",
  inPersonExamination: "Személyes vizsgálat",
  onlineVideoConsultation: "Online videókonzultáció",
  connectToVideoConsultation: "Csatlakozás a videókonzultációhoz",
  needPetOwnerEmailError:
    "A videókonzultációhoz a gazda e-mail címe szükséges.",
  setPetOwnerEmailLink: "E-mail cím beállítása.",

  // Video consultation monitor
  roomId: "Szoba azonosító",
  noActiveRoomsMessage:
    "Jelenleg minden szoba üres, mert senki sem használja a videóchat szolgáltatást.",
  videoConsultationRoomMonitor: "Videókonzultáció szoba monitor",
  videoChatUser: "Videóchat felhasználó",
  joinDate: "Csatlakozás dátuma",
  chatDuration: "Chat időtartama",
  stateOfMediaDevices: "Médiaeszközök állapota",
  micEnabled: "A mikrofon be van kapcsolva.",
  micDisabled: "A mikrofon ki van kapcsolva.",
  camEnabled: "A kamera be van kapcsolva.",
  camDisabled: "A kamera ki van kapcsolva.",
  micWorking: "A mikrofon megfelelően működik.",
  micMissing: "A mikrofon nem található.",
  micError: "A mikrofont nem sikerült megfelelően beállítani.",
  camWorking: "A kamera megfelelően működik.",
  camMissing: "A kamera nem található.",
  camError: "A kamerát nem sikerült megfelelően beállítani.",
  roomIsBeingDeleted: "Ez a szoba hamarosan törlésre kerül...",

  // NumberFormatter
  thousandsSeparator: "\u2009",

  willBeSentToEmail:
    "A kórlapból generált pdf a következő email címre lesz elküldve:",

  mute: "Némít",
  anyVet: "Bármelyik állatorvos",
  appointmentPeriodOnOverlappingFreeTimeSlots:
    "Az időpont nem megfelelő a klinikai dolgozók beosztásának. Próbálja meg szabad idősáv elején, vagy végén, vagy válasszon ki egy állatorvost!",
  changeLogo: "Logó váltás",
  uploadLogo: "Logó feltöltés",
  deleteLogo: "Logó törlés",
  unsupportedFileType:
    "A kép fájltípusa nem támogatott. A támogatott fájltípusok a következők:",
  changeProfilePicture: "Profilkép váltás",
  uploadProfilePicture: "Profilkép feltöltés",
  deleteProfilePicture: "Profilkép törlése",
  pictureChanged: "Sikeres képmódosítás.",
  prescriptionOnlyMode: "eRecept-mód",
  prescriptionOnlyModeTooltip:
    "Az eRecept-módban a klinika csak recepteket állíthat ki, a rendszer többi funkcióhoz nem férhet hozzá.",
  switchToPrescriptionOnlyMode: "Váltás eRecept-módra",
  fullMode: "Teljes mód",
  switchToFullMode: "Váltás teljes módra",
  switchToPrescriptionOnlyModeWarning:
    "A klinike eRecept-módra váltásával más funkciók elérhetetlenek lesznek, mint a Raktárkezelés, Számlakezelés, Kórlapkezelés és Műszakkezelés.",
  switchToFullModeWarning:
    "A klinika teljes módra váltásával további költségek is terhelik a klinikát, amelyek magába foglalják a klinikában dolgozó felhasználók utáni előfizetést is.",
  switch: "Váltás",
  usePrescriptionOnlyMode: "eRecept-mód használata",
  usageMode: "Használati mód",

  // Support
  helpCenter: "Súgóközpont",
  guidesForGettingStarted: "Útmutatók a kezdéshez",
  topSupportTopics: "Legnépszerűbb témák",
  seeAllSupportTopics: "Összes téma megtekintése",
  supportTopics: "Témák",
  stripeIntegrationSupportContent:
    "Az online fizetések lebonyolítására a {0} keretrendszert használja a GlobalVet. A Stripe használata regisztrációhoz kötött, csomagtól függően a fizetések kezeléséért további díjakat számolhatnak fel. A Stripe integrálásához szükséges lépések:",
  stripeIntegrationSupportStep1: "Stripe fiók létrehozása",
  stripeIntegrationSupportStep2: "Stripe kulcsok másolása",
  stripeIntegrationSupportStep3: "Stripe kulcsok beillesztése",
  needToContactUs: "Kapcsolatba szeretne lépni velünk?",
  feelFreeToCotactUs:
    "Bármi kérdés esetén lépjen kapcsolatba velünk a következő e-mail címen: {0}.",
  registerFirst:
    "Ehhez a művelethez felhasználói fiókra lesz szüksége, ha még nincs, {0}.",
  userRegistration: "Felhasználó létrehozása",
  userRegistrationContent:
    "Csak egyetlen regisztrációra van szükség, függetlenül attól, hogy Ön háziállat-tulajdonos vagy állatorvosi dolgozó. A regisztráció után bejelentkezve kiválaszthatja, hogy háziállat-tulajdonosként vagy állatorvosi dolgozóként kíván-e eljárni.",
  clinicRegistration: "Rendelő létrehozása",
  clinicRegistrationContent:
    "Menedzseljen akár több rendelőt is, mindegyik önálló cégként regisztrálható. Miután sikeresen létrehozta rendelőjét, már hívhatja is kollégáit.",
  becomePetOwner: "Gazdi lennék",
  becomePetOwnerContent:
    "Váltson gazdi módra a felhasználói menüből. Innen már csak kedvencét kell regisztrálnia és foglalhatja is első időpontját bármelyik klinikához.",
  becomeClinicEmployee: "Rendelő alkalmazottja lennék",
  becomeClinicEmployeeContent:
    "Váltson rendelő alkalmazott módra a felhasználói menüből. Alapesetben itt láthatóak a létrehozott rendelők és a meghívások. Amikor egy rendelő tulajdonosa felkéri Önt, hogy csatlakozzon a rendelőjéhez, e-mail üzenetet és értesítést is fog kapni róla.",
  supportTicketHeader: "Új hibajegy létrehozása",
  supportTicketTitle: "Cím",
  supportTicketBody: "Üzenet",
  supportTicketType: "Típus",
  supportTicketTypeChoose: "-- Válassz egy hibajegy típust --",
  supportTicketFullName: "Teljes név",
  supportTicketEmail: "Email cím",
  supportTicketPhoneNumber: "Telefonszám",
  supportTicketLanguage: "Nyelv",
  supportTicketSendButton: "Hibajegy leadása",

  supportTicketCreated:
    "Hibajegye feladásra került. Hamarosan kapni fog egy emailt benne egy linkkel az elkészített hibajegyére.",

  supportTicketChatAnswerTitle: "Új üzenet",
  supportTicketChatAnswerButton: "Küldés",

  supportTicketState: "Állapot",
  supportTicketIsClosed: (date: string): string =>
    `Hibajegye lezárásra került ekkor: ${date}. Köszönjük megkeresését!`,

  // Support Ticket Types
  supportTicketTypeError: "Rendszer hiba",
  supportTicketTypeSupport: "Support",
  supportTicketTypeSubscription: "Előfizetés",

  // Support Ticket States
  supportTicketOpen: "Nyitott",
  supportTicketClosed: "Lezárt",
  backToTicketsPage: "Vissza a hibajegyekhez",
  myTickets: "Hibajegyek",
  closed: "Lezárt",
  createTicket: "Hibajegy létrehozása",
  subject: "Tárgy",
  supportTicketDescription: "Leírás",
  invalidTicketUrl: "Érvénytelen URL",
  ticketNotFoundOrNotAuthorized: "A hibajegy nem elérhető.",
  help: "Segíthetünk?",
  siteManagerMode: "Adminisztrátor mód",
  shiftSuccessfullyModified: "A műszakot sikeresen módosította.",
  weAreSorry: "Sajnáljuk.",

  // Feedbacks
  feedbacks: "Visszajelzések",
  survey: "Kérdőív",
  sendFeedback: "Küldés",
  feedbackAlreadySent: "Már korábban elküldte a véleményét. Köszönjük!",
  feedbackSuccess: "Köszönjük a visszajelzést!",
  feedbackQuestion: "Mit gondol a rendszerünkről?",
  feedbackAnswerPlaceholder: "Kérjük, írja le a véleményét!",
  feedbackAge: "éves",
  feedbackPets: "kisállat",
};
