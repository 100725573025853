/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState, ReactElement } from "react";
import jwtDecode from "jwt-decode";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Link, useParams } from "react-router-dom";
import { PageProps } from "../../models/PageProps";
import { useUser } from "../../contexts/UserContext";
import AlertBox from "../../components/AlertBox";
import { useChosenRole } from "../Main/Onboarding/ChooseRole";
import { getAccessToken } from "../../util/LocalStorageVariables";
import { strings } from "../../common/Strings/Strings";
import FeeSection from "./Fees/FeeSection";
import PolicySection from "./Policy/PolicySection";
import CountryApi from "../../api/CountryApi";
import { getGeneralError } from "../../util/helperFunctions";
import { CountrySettingsResponse } from "../../models/management/CountrySettingsResponse";
import { Loader } from "../../components/Loader";
import PaymentAndBillingSection from "./PaymentAndBilling/PaymentAndBillingSection";
import InsuranceConfigSection from "./InsuranceConfigSection";
import { ChevronRight } from "../../common/Icons/ChevronRight";

export const CountrySettingsPage = (props: PageProps): ReactElement => {
  const authorities: string[] = jwtDecode(getAccessToken())?.authorities || [];
  const { user } = useUser();
  const { setPageLoading } = props;
  const { countryCode, tab } = useParams<"countryCode" | "tab">();
  const [key, setKey] = useState<string>(tab || "fees");
  const [country, setCountry] = useState<CountrySettingsResponse>();
  const [error, setError] = useState<string | null>(null);

  useChosenRole("manager");

  useEffect(() => {
    const getCountrySettings = async (code: string) => {
      setPageLoading(true);
      try {
        const resp = await CountryApi.getCountrySettingsByCode(code);
        setCountry(resp.data);
        setPageLoading(false);
      } catch (err) {
        setError(await getGeneralError(err));
        setPageLoading(false);
      }
    };

    if (countryCode) {
      void getCountrySettings(countryCode);
    }
  }, [countryCode]);

  const hasAuthority = (authority: string): boolean => {
    return (
      authorities.includes("MANAGER_FULL") || authorities.includes(authority)
    );
  };

  if (!user.isSiteManager) {
    setPageLoading(false);
    return <AlertBox message="Forbidden" className="m-3" closeAble={false} />;
  }

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6 space-y-6">
          <AlertBox hidden={error == null} message={error} className="my-3" />

          <div className="flex items-center space-x-2">
            <div>
              <Link to="/management/settings" className="lg:text-2xl">
                {strings.settings}
              </Link>
            </div>
            <ChevronRight />
            <span className="lg:text-2xl"> {country?.countryName}</span>
          </div>
          <div>
            <div
              id="settings-tabs"
              className="flex content-center flex-col settings-tabs"
            >
              <Tabs
                className="justify-content-center"
                id="managementTabs"
                activeKey={key}
                onSelect={(k: any): void => {
                  setKey(k);
                }}
              >
                {hasAuthority("MANAGER_FEES") && (
                  <Tab eventKey="fees" title={strings.fees}>
                    <div>
                      {countryCode && (
                        <FeeSection
                          isActiveTab={key === "fees"}
                          countryCode={countryCode}
                        />
                      )}
                    </div>
                  </Tab>
                )}

                {hasAuthority("MANAGER_CONFIG") && (
                  <Tab
                    eventKey="payment-and-billing"
                    title={strings.paymentAndBilling}
                  >
                    <div>
                      {countryCode && (
                        <PaymentAndBillingSection
                          isActiveTab={key === "payment-and-billing"}
                          countryCode={countryCode}
                        />
                      )}
                    </div>
                  </Tab>
                )}

                {hasAuthority("MANAGER_CONFIG") && country && (
                  <Tab eventKey="insurance" title={strings.insuranceTitle}>
                    <div>
                      {countryCode && (
                        <InsuranceConfigSection
                          isActiveTab={key === "insurance"}
                          countryCode={countryCode}
                        />
                      )}
                    </div>
                  </Tab>
                )}
                {hasAuthority("MANAGER_POLICIES") && country && (
                  <Tab eventKey="policies" title={strings.policies}>
                    <div>
                      <PolicySection
                        isActiveTab={key === "policies"}
                        country={country}
                      />
                    </div>
                  </Tab>
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Loader(CountrySettingsPage);
